/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}


.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus { outline: none; }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.home-header-slider-cover {
    .slick-list {
        overflow: visible;
    }
}


.slick-slider {
    .slick-track, .slick-list { transform: translate3d(0, 0, 0); }
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before, &:after {
        content: "";
        display: table;
    }

    &:after { clear: both; }

    .slick-loading & { visibility: hidden; }
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;

    [dir="rtl"] & { float: right; }

    img { display: block; }

    &.slick-loading img { display: none; }

    display: none;

    &.dragging img { pointer-events: none; }

    .slick-initialized & { display: block; }

    .slick-loading & { visibility: hidden; }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slick-arrow.slick-hidden { display: none; }


.set-slider-wrapper {
    .slick-next {
        margin-left: 70px;
    }
    .slick-prev {
        margin-right: 70px;
    }
}