.filter-cover, .filter-brand-cover {
  border-top: 2px solid $black;
  border-bottom: 2px solid $black;
  user-select: none;
  //-webkit-overflow-scrolling: touch;

  .filter-close {
    position: fixed;
    z-index: -1;
    bottom: 0;
    left: 0;
    right: 0;

  }

  .dropdown {
    .dropdown-block {
      width: 100%;
      min-width: 100%;

      &.size-dropdown-width {
        min-width: 320px !important;
        //padding-right: 11px;
      }

      &.price-dropdown-width {
        width: 292px;
        padding-right: 11px;

        .checkbox-icon {
          float: left;
          width: calc(50% - 9px);
          margin-top: 9px;
          margin-right: 9px;

          label {
            width: 100%;
          }
        }
      }

      &.color-dropdown-width {
        min-width: 160px;
        max-height: none;

        //color shemes
        $color-light: #ffffff, #f7fcfd, #f7fcf0, #fffee5, #fff7eb, #fff8fb, #f7f4f9, #fbfafa;
        $color-neutral: #afa2a1, #9f9697, #a79e9e, #b4a9a8, #cabeb8, #e0d1ca, #ebdbd1, #fae5d5;
        $color-dark: #2e2111, #1b1309, #000002, #1b1309, #22180c, #281c0f, #2d2011, #362614;
        $color-red: #ff7d7b, #ef472f, #ca1409, #ae0000, #bf002c, #d4014f, #ec1368, #f0658f;
        $color-yellow: #ffe938, #ffda00, #ffc200, #f4b200, #f09d00, #f2b200, #f2cb00, #f9e000;
        $color-green: #c0f072, #9cde65, #77c85c, #55b559, #41a161, #149062, #5e9743, #99af56;
        $color-blue: #68b7e6, #419dd2, #347ebd, #2a60b5, #1647b6, #003cac, #1e2e93, #3d359a;

        &[data-sheme]:not([data-sheme="pattern"]) {
          .checkbox-color:last-child label {
            background-image: none;
          }
        }

        &[data-sheme="light"] {
          @for $i from 1 through length($color-light) {
            .checkbox-color:nth-child(#{$i}) label {
              background-color: nth($color-light, $i);
            }
          }

          .checkbox-color {
            label span {
              color: $grey;
              opacity: 0.5;
            }

            &:nth-child(1) label {
              border: 1px solid $lightest;
              border-bottom: none;
              span {
                opacity: 1;
              }
            }
          }
        }

        &[data-sheme="neutral"] {
          @for $i from 1 through length($color-neutral) {
            .checkbox-color:nth-child(#{$i}) label {
              background-color: nth($color-neutral, $i);
            }
          }

          .checkbox-color {
            label span {
              opacity: 0.4;
            }

            &:nth-child(2), &:nth-child(8) {
              label span {
                opacity: 1;
              }
            }
            &:nth-child(5) label span {
              opacity: 0.5;
            }
            &:nth-child(6) label span {
              opacity: 0.7;
            }
            &:nth-child(7) label span {
              opacity: 0.9;
            }
          }
        }

        &[data-sheme="dark"] {
          @for $i from 1 through length($color-dark) {
            .checkbox-color:nth-child(#{$i}) label {
              background-color: nth($color-dark, $i);
            }
          }

          .checkbox-color {
            label span {
              opacity: 0.2;
            }

            &:nth-child(3) label span {
              opacity: 1;
            }
          }
        }

        &[data-sheme="red"] {
          @for $i from 1 through length($color-red) {
            .checkbox-color:nth-child(#{$i}) label {
              background-color: nth($color-red, $i);
            }
          }

          .checkbox-color {
            label span {
              opacity: 0.3;
            }

            &:nth-child(1), &:nth-child(8) {
              label span {
                opacity: 0.4;
              }
            }
            &:nth-child(4) label span {
              opacity: 1;
            }
            &:nth-child(7) label span {
              opacity: 0.35;
            }
          }
        }

        &[data-sheme="yellow"] {
          @for $i from 1 through length($color-yellow) {
            .checkbox-color:nth-child(#{$i}) label {
              background-color: nth($color-yellow, $i);
            }
          }

          .checkbox-color {
            label span {
              opacity: 0.6;
            }

            &:nth-child(1), &:nth-child(5) {
              label span {
                opacity: 1;
              }
            }
            &:nth-child(2), &:nth-child(8) {
              label span {
                opacity: 0.8;
              }
            }
            &:nth-child(7) label span {
              opacity: 0.7;
            }
          }
        }

        &[data-sheme="green"] {
          @for $i from 1 through length($color-green) {
            .checkbox-color:nth-child(#{$i}) label {
              background-color: nth($color-green, $i);
            }
          }

          .checkbox-color {
            label span {
              opacity: 0.3;
            }

            &:nth-child(1) label span {
              opacity: 0.7;
            }
            &:nth-child(2) label span {
              opacity: 0.5;
            }
            &:nth-child(6) label span {
              opacity: 1;
            }
            &:nth-child(8) label span {
              opacity: 0.35;
            }
          }
        }

        &[data-sheme="blue"] {
          @for $i from 1 through length($color-blue) {
            .checkbox-color:nth-child(#{$i}) label {
              background-color: nth($color-blue, $i);
            }
          }

          .checkbox-color {
            label span {
              opacity: 0.3;
            }

            &:nth-child(1) label span {
              opacity: 0.4;
            }
            &:nth-child(7) label span {
              opacity: 1;
            }
          }
        }

        &[data-sheme="pattern"] {
          @for $i from 1 through length($color-blue) {
            .checkbox-color:nth-child(#{$i}) label {
              background-color: nth($color-default, $i);
              background-image: url('%=static=%general/patterns/#{$i}.png');
            }
          }
        }
      }

      &.designer-dropdown-width, &.dropdown-checklist {
        width: 300px;
      }

      &.dropdown-checklist {
        padding: 10px 20px;
      }

      &.filter-list-dropdown {
        min-width: 180px;
      }
    }
  }
}

.filter-delimeter {
  @extend .l;
  margin-top: 16px;
  height: 14px;
  width: 1px;
  background-color: $light;
}

.filter-cover_title {
  @extend .text-center;
  @extend .h4;
  color: white;
  background-color: $black;
  height: 52px;
  padding: 0 20px ;
  align-items: center;
  justify-content: center;
  transition: height 0.4s;
  cursor: pointer;
  @include respond-to(t) {
    display: flex !important;
  }
}

.filter-icon {
  &:after {
    content: 'filter_list';
  }

  .open &:after {
    content: 'close';
  }
}

.size-popup-dropdown {
  &, & .dropdown-block {
    width: 100px;
  }
}

.parent-category {
  @extend .border-top-light;
  margin-top: 10px;

  label {
    @extend .bold;
  }
}

@media screen and (max-width: ($screen-md - 1px)) {
  .filter-cover, .filter-brand-cover {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    //@include z-filter;
    z-index: 10;
    height: 52px;
    transition: height 0.4s;
    &.open {
      height: calc(100% - 65px);
      .filter-close {
        cursor: pointer;
        z-index: 10;
      }
      .filter-cover_title {
        background-color: white;
        color: $black;
        border-bottom: 1px solid $black;
        font-size: 24px;
        text-transform: none;
        height: 60px;
        justify-content: flex-end;

        span {
          margin-right: auto;
        }
        .filter-cancel-all_mob {
          display: block;
        }
      }

    }
  }

  .filter-cover_content {
    height: calc(100% - 100px);
    background-color: white;
    overflow-y: scroll;

    .dropdown {
      width: 100%;

      .dropdown-head {
        border-top-color: $black;
        border-bottom-color: $black;
        border-left: none;
        border-right: none;

        text-transform: uppercase;
        font-family: $BoldFont;
        font-size: 12px;

        height: 68px;
        line-height: 68px;

        &:after {
          content: none;
        }

        &:before {
          content: '+';
          font-size: 28px;
          font-family: $RegularFont;
          vertical-align: middle;
          margin-right: 20px;
        }
      }

      .dropdown-block {
        position: static;
        max-height: none;
        overflow-y: auto;
        border: none;
        border-bottom: 1px solid $black;
      }

      &:nth-last-child(2) .dropdown-head {
        border-bottom-width: 2px;
      }

      &.open .dropdown-head:before {
        content: '\2212';
      }

      .checkbox, .radio {
        input[type="radio"], input[type="checkbox"] {
          & + label {
            color: $black;
            &:before {
              border-color: $black;
              border-width: 2px;
            }
          }
        }
        &.disabled input[type="radio"] + label, &.disabled input[type="checkbox"] + label {
          color: #b1b9c1;
        }
      }
    }
  }
}

@media screen and (min-width: $screen-md) {
  .filter-cover, .filter-brand-cover {

    .dropdown {
      float: left;

      .dropdown-head {
        margin: 13px 0;
        line-height: 22px;
        height: 22px;
        border-right: none;
        border-bottom: none;
        border-top: none;
        padding: 0 16px;
      }

      &:first-child .dropdown-head {
        border-left-color: white;
      }

      &.open {
        .dropdown-head {
          margin: 0;
          line-height: 48px;
          height: 48px;
          border-right: 1px solid $light;

          &:first-child {
            border-left: 1px solid $light;
          }
        }

        & + .dropdown .dropdown-head {
          border-left-color: white;
        }
      }

      &:first-child.open .dropdown-head {
        border-left: 1px solid $light;
      }

      &.filter-sorting .dropdown-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: initial;
      }
    }
  }

  .filter-cover .dropdown {
    width: 16.666%;
  }

  .subcategory-filter-cover .dropdown {
    width: calc(100% / 5);
  }

  .filter-brand-cover .dropdown {
    width: 20%;
  }
}

.filter-cancel,
.filter-cancel-all {
  color: #b1b9c1;
  opacity: 0;
  transition: all 0.2s;
}

.filter-cancel.active,
.filter-cancel-all.active {
  color: #000;
  opacity: 1;

  &:hover {
    color: #616873;
  }
}

.filter-cancel-all_mob {
  font-family: $RegularFont;
  color: #000;
  font-size: 13px;
  text-transform: none;
  margin: 0 10px;
  display: none;
}
