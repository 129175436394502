$menu-width: 690px;
$header-height: 80px;
$header-big-height: 150px;

.header-height {
  &, & .header {
    height: 60px;
  }

  @media (min-width: $screen-md-min) {
    height: $header-big-height;
    & .header {
      height: $header-height;
      transition: height 0.4s;
    }

    &.open {
      &, & .header {
        height: $header-big-height;
      }
    }
  }

  @media (max-width: $screen-sm-max) and (min-width: $screen-xs-min) {
    height: 70px;
  }
}

.header {
  @include z-header-menu;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background-color: white;
  z-index: 10; //prevent pin images on header

  @media (max-width: $screen-sm-max) {
    margin-right: -5%;

    &.open {
      bottom: 0;
      right: 0;
      //position: fixed;
      //width: 100%;
      //top: 0; left: 0;
      //background-color: white;

      .header-first {
        display: flex;
        @include respond-to(t) {
          display: none !important;
        }
      }
    }
  }
  @media (max-width: $screen-xs-max) {
    margin-right: calc(3px - #{$grid-gutter-width});

  }
}

.header-first {
  padding: 30px 0 0px;
  display: none;
  @include respond-to(t) {
    display: none !important;
  }
  @media (max-width: $screen-sm-max) {
    position: absolute;
    background-color: white;
    top: 70px;
    left: 0;
    right: 20px; //0;
    height: calc(100vh - 70px);
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;

    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;

    //display: none;
  }

  @media (max-width: $screen-xs-min) {
    top: 60px;
    height: calc(100vh - 60px);
  }

  .subscribe-form {
    width: 100%;
  }

  @media (min-width: $screen-xs-min) and (max-width: $screen-sm-max) {
    .subscribe-form {
      margin-left: 70px;
      width: calc(100% - 140px);
    }
  }
}

.header-second {
  @media (min-width: $screen-md-min) {
    padding: 20px 0;
  }
}

.header-static {
  @media (min-width: $screen-md-min) {
    max-width: $menu-width;
    margin: 0 auto;
  }
}

.header-menu {
  @media (min-width: $screen-md-min) {
    ul {
      margin: 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      //justify-content: space-between;
      align-content: center;

      margin-bottom: -30px;

      .last {
        text-align: right;
        margin-left: -15px;
      }
    }
    > ul > li {
      flex: 1 1 auto;
      text-align: center;
      padding-bottom: 30px;
    }

  }
  @media (max-width: $screen-sm-max) {
    ul {
      max-height: calc(100vh - 260px);
      overflow-y: scroll;
      overflow-x: hidden;
    }

    li {
      text-align: center;
      height: 70px;
      line-height: 70px;

      &:not(.second) {
        font-family: $BoldFont;
        font-size: $h3-size;
      }

      //child
      &.second {
        font-size: $text-base;
        display: none;
        border-bottom: 1px solid $lightest;

        &:nth-child(2) {
          border-top: 1px solid $lightest;
        }
      }

      //parent item
      &.header-menu-item-cover {
        margin-left: 15px;
        &:after {
          font-family: 'Material Icons';
          content: '\e313';
          display: inline-block;
          vertical-align: middle;
          font-size: $text-small;
          transition: transform $time;
          width: 20px;
        }

        &.open {
          &:after {
            transform: rotate(180deg);
          }

          & ~ .second {
            display: block;
          }
        }
      }
    }
  }
  @media (max-width: $screen-sm-max) and (max-height: 800px) {
    li {
      height: 50px;
      line-height: 50px;
    }
  }

  @include respond-to(d) {
    .has-submenu:hover {
      //position: relative;

      .second__wrap {
        position: relative;
      }

      .second__wrap:after {
        content: '';
        position: absolute;
        top: calc(100% + 8px);
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 6px 0 6px;
        border-color: rgb(177, 185, 193) transparent transparent;
      }
    }

    li {
      height: 100%;
    }

    > ul {
      position: relative;
    }

    .header-menu__submenu {
      justify-content: flex-start;
    }
  }

}

.header-menu__submenu {
  @include respond-to(d) {
    position: absolute;
    margin-top: -5px;
    left: 0;
    width: 100vw;
    top: calc(100% - 20px);
    border: 1px solid rgb(234, 239, 243);
    background-color: #fff;
    opacity: 0;
    visibility: inherit;
    transform: matrix(1, 0, 0, 1, 0, -12);
    transition: transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1), opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
    pointer-events: none;
    padding: 40px 75px;
    z-index: 5;

    li {
      width: 33%;
      margin-top: 10px;
      margin-bottom: 10px;
      text-align: left;
    }

    &.active {
      pointer-events: auto;
      opacity: 1;
      transform: matrix(1, 0, 0, 1, 0, 0);
    }
  }
}

.menu-menu_cover {
  margin-left: 80px;

  @media (max-width: 424px) {
    margin-left: 60px;
  }
}

.menu-icon {
  @extend .pointer;
  margin-top: 31px;
  margin-left: -3px;

  &-line {
    height: 2px;
    width: 20px;
    margin-top: 4px;
    margin-left: 3px;
    margin-right: 3px;
    background-color: $black;
    transition: all $time;
  }

  @media (min-width: $screen-md-min) {
    transition: transform $time;

    &:hover {
      .menu-icon-line {
        margin-left: 0;

        &:nth-child(2) {
          margin-right: 0;
          margin-left: 6px;
        }
      }
    }

    &.open {
      transform: rotate(90deg);
    }
  }
  @media (max-width: $screen-sm-max) {
    &.open {
      //padding-top: 5px;

      .menu-icon-line {
        &:nth-child(1) {
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
          margin-top: -8px;
        }
      }
    }
  }
  @media (max-width: $screen-xs-max) {
    margin-top: 27px;
  }
  @media (max-width: $screen-xs-min) {
    margin-top: 22px;

    &.open {
      margin-top: 28px;
    }
  }
}

.logo {
  @extend .block;
  font-size: $h3-size;
  letter-spacing: 0.6em;

  &:hover:after {
    content: none;
  }
}

.header-line {
  height: 18px;
  margin-right: 200px;
  transition: margin-right 0.4s;
}

.header-favorite, .header-cart {
  padding-top: 6px;
  padding-bottom: 6px;
}

.header-favorite {
  padding-left: 15px;

  &:hover {
    &:after {
      content: none;
    }

    .ico-favorite {
      opacity: 1;
    }
  }
}

.header-cart {
  @media (max-width: $screen-sm-max) {
    background-color: $lightest;
    padding: 21px 5px 21px 15px;
    width: 50px;
    height: 28px;

    .label {
      vertical-align: middle;
    }
  }
  @media (max-width: $screen-xs-min) {
    padding: 16px 0 16px 10px;
  }
  @media (min-width: $screen-md-min) {
    padding-right: 15px;
  }
}

.header-phone-mobile {
  @extend .text-center;
  padding: 25px;
  width: 20px;
  height: 17px;

  @media (max-width: $screen-xs-min) {
    padding: 20px;
  }
}

.ico-cart {
  @extend .v-middle;
  @include bg-svg($cart_mobile);
}

.ico-phone {
  @include bg-svg($phone_mobile);
}

.ico-cart, .ico-phone {
  @media (min-width: $screen-sm-min) {
    transform: scale(1.3, 1.3);
  }
}

.header-left {
  float: left;

  @media (min-width: $screen-md-min) {
    width: 153px;
  }
  @media (max-width: $screen-sm-max) {
    width: calc(100% - 190px);
    text-align: center;
    margin-left: 40px;
  }
  @media (max-width: 424px) {
    width: calc(100% - 130px);
    margin-left: 0;
  }

  .logo {
    padding-top: 6px;

    @media (max-width: $screen-sm-max) {
      padding-top: 23px;
      font-size: 16px;
    }
    @media (max-width: $screen-xs-min) {
      padding-top: 20px;
      font-size: 14px;
    }
  }
}

.header-cover {
  @media (max-width: $screen-sm-max) {
    float: right;
  }
}

.header-menu-cover, .header-cover {
  @media (min-width: $screen-md-min) {
    margin-right: 170px;
    margin-left: 170px;
  }
}

.header-right {
  transition: width 0.4s;

  @media (max-width: $screen-sm-max) {
    text-align: center;
  }
  @media (min-width: $screen-md-min) {
    float: right;
    width: 153px;
  }
}

.breadcrumbs {
  ul {
    margin: 0;
  }
  li {
    @extend .l;
    @extend .small;
    padding-top: 10px;

    a {
      margin-right: 30px;
    }

    //span {
    //  color: $grey;
    //}
  }
}

.ico-favorite {
  @extend .inline-block;
  @extend .v-middle;
  @include bg-svg($heart-small);
  //@include bg-svg($heart_outline);
  //transform: scale(0.7, 0.7);
  opacity: 0.5;
  margin-right: 10px;

  &:hover {
    opacity: 1;
  }
}

//sign - log in

.signin-popup {
  @extend .z-signin;
  @extend .fix;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  padding: 20px;
  display: none;

  &-body {
    height: calc(100vh - 70px);
    display: flex;
    //justify-content: center;
    justify-content: flex-start;
    align-content: center;
    flex-direction: column;
    flex-wrap: nowrap;

  }
  &-content {
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 20px;
    @include respond-to(t) {
      padding-bottom: 50px;
    }
  }
  @include respond-to(t) {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
}

.desktop-search {
  @extend .cover;
  @extend .rel;
  width: 30px;
  transition: all 0.4s;

  input[type="text"] {
    border-bottom: 2px solid $black;
    line-height: 1;
  }

  .btn {
    top: 0;
  }
}

.desktop-search .btn, .desktop-search-open {
  @extend .abs;
  @include square(30px);
  line-height: 30px;
  right: 0;
  display: block;
  padding: 0;
  .material-icons {
    @include square(30px);
    display: block;
    text-align: center;
  }
}

.desktop-search-open {
  top: 0px;

  z-index: 3;
  cursor: pointer;
  border-bottom: 2px solid white;
  background-color: white;
  text-align: center;
  transition: height 0.1s 0.3s;
}

.search-open {
  .desktop-search {
    width: 270px;
    margin-left: 40px;
  }

  .desktop-search-open {
    height: 0px;
    transition: none;
  }

  .header-right {
    width: 460px;
  }

  .header-line {
    margin-right: 460px;
  }
}

.second_a.menu-item--sale{
  @media screen and (max-width: 1200px){
    color: $dark-red;
  }
}

.second_a.menu-item--sale:before,
.second_a.menu-item--sale:after{
  color: $dark-red;
}

.menu_mob {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 12;
  visibility: hidden;
  @include respond-to(d) {
    display: none !important;
  }
  &.active {
    visibility: visible;
    .menu_mob_mask {
      opacity: 1;

    }
    .menu_mob_content {
      transform: translateX(0%);
    }
  }
  &_mask {
    background: rgba(0, 0, 0, 0.50);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    transition: all .5s;
    cursor: pointer;
  }
  &_content {
    background: #fff;
    position: relative;
    z-index: 2;
    height: 100%;
    width: calc(100% - 100px);
    padding: 0 20px;
    overflow: scroll;
    transform: translateX(-100%);
    transition: all .5s;
  }
  .ico-phone {
    display: block;

  }
  .user-shape {
    @include bg-svg($user-shape);
    display: block;
  }
  &_top {
    display: flex;
    align-items: center;
    height: 60px;
    &-w100 {
      margin-right: auto;
    }
  }
  &_second {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    a {
      font-size: 14px;
    }
  }
  &_link {
    padding: 0 20px;
    display: block;
  }
  &_soc {
    display: flex;
    align-items: center;
    height: 60px;
    justify-content: flex-start;
    .soc-fb:first-child {
      margin-left: 0;
    }
  }
  &_ul {
    border-top: 1px #eaeff3 solid;
    margin-top: 0;
  }
  .second {
    border-bottom: 1px #eaeff3 solid;
    a {
      display: block;

      font-size: 15px;
      padding: 20px 0;

      width: 85%;
    }
  }

  .has-submenu {
    @include respond-to(t) {
      &.opened {
        border-bottom: none;

        .second__wrap:after {
          transform: translateY(-50%) rotate(180deg);
        }
      }

      .second__wrap {
        position: relative;
        display: block;
        cursor: pointer;
        &:after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 5px 0 5px;;
          border-color: rgb(177, 185, 193) transparent transparent;
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);
          transition: transform .3s;
        }
      }
    }
  }

  .header-menu__submenu {
    @include respond-to(t) {
      background-color: #f2f6fa;
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      display: none;
      a {
        border: none;
      }
    }
  }
  &_close {
    @include bg-svg($close_mob_menu);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;
  }

}

.search-mob {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 13;
  padding: 100px 20px;
  box-sizing: border-box;
  visibility: hidden;
  opacity: 0;
  transition: all .5s;
  &.active {
    visibility: visible;
    opacity: 1;
  }
  &_close {
    @include bg-svg($close_search-mob);
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  input {
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px #000 solid;
    padding: 0 10px;
    height: 30px;
    border-radius: 0;
  }
}

.search-mob-open {
  cursor: pointer;
}

.form-group-password {
  position: absolute;
  right: 0;
  top: 16px;
  transform: translateY(-50%);
  color: #b1b9c1;
  cursor: pointer;
}

.checkout {
  margin-top: 10vh;

  @include respond-to(t) {
    margin-top: 70px;
    .inline-block {
      vertical-align: middle;
    }
    svg {
      margin-right: 20px;
    }
  }


  .ico-phone {
    margin-right: 20px;
  }
  .r-marg {
    margin-right: 150px;
    margin-bottom: 0;
    &.cart-open-btn {
      cursor: pointer;
    }
  }

  .small {
    .v-dropdown-opener {
      margin-bottom: 0;
    }
    .v-dropdown-block.bottom {
      top: 100%;
      margin-top: 10px;
      transform: translate(-50%, 0);
      width: auto;
      white-space: nowrap;
      z-index: 999;
    }
  }
}

.order-basket {
  .cart-remove {
    pointer-events: none;
    color: #d1d1d1 !important;
  }
  .count-dec, .count-inc {
    pointer-events: none;
    color: #d1d1d1 !important;
  }
  .block-right_footer {
    display: none;
  }
}

.first-popup-ru, .first-popup-en {
  display: none;
}

.header-account-link {
  padding-top: 2px;

  > * {
    margin-left: 15px;
  }
}

.currency_dropdown {
  position: relative;
  cursor: pointer;
  display: inline-block;

  &.active .currency_list {
    display: block
  }
}

.currency_list {
  border: 1px solid rgb(234, 239, 243);
  background-color: rgb(255, 255, 255);
  text-align: center;
  padding: 15px;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 50px;
  box-sizing: border-box;
  display: none;

  li {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.currency_list-mob {
  display: flex;
  margin: 0;

  li {
    margin-right: 30px;
    a {
      color: #b1b9c1;
    }

    &.active a {
      color: #000;
    }
  }
}

