.btn {
  @extend .bold;
  @extend .rel;
  @extend .uppercase;
  @extend .text-center;
  @extend .inline-block;
  vertical-align: top;
  box-sizing: border-box;
  background-color: transparent;
  height: $btn-height;
  line-height: $btn-height;
  width: $btn-width;
  border: none;
  font-size: $text-small;
  border-radius: 0px;

  &:before {
    @extend .abs;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    transform: scale(0, 1);
    transform-origin: right center 0;
    transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  }

  &, &:active, &:focus {
    outline: none;
  }

  span {
    @extend .rel;
    @extend .inline-block;
    z-index: 2;
  }
  &.disabled {
    opacity: 0.5 !important;
    pointer-events: none !important;
  }
}

input.btn {
  transition: background-color $time;
}

a.btn {
  text-decoration: none;
}

.btn-light {
  background-color: white;
  border: 2px solid $black;
  color: $black;
  line-height: $btn-height - 4px;

  &:before {
    background-color: $black;
  }
}

.btn-dark {
  background-color: $black;
  color: white;

  &:before {
    background-color: $darkest;
  }
}

//.page__wrapper .btn-load-more {
//  font-size: 16px;
//  letter-spacing: 1px;
//  margin-top: 80px;
//
//  @media screen and (max-width: $screen-xs-max){
//    margin-top: 40px;
//    margin-bottom: 60px;
//  }
//}

.form-group {
  @extend .rel;
  width: $btn-width;
  max-width: 100%;

  label {
    @extend .abs;
    color: $grey;
    top: 8px;
    left: 10px;
    //left: 0px;
    transition: top $time, font $time;

    &.focus{
      top: -16px;
      font-size: 13px;
    }

    &:not(.click) {
      pointer-events: none;
    }
  }

  input[type="text"], input[type="email"], input[type="password"], input[type="tel"], textarea {
    @extend .regular;
    @extend .w100p;
    box-sizing: border-box;
    background-color: transparent;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $grey;
    height: 32px;
    font-size: 16px;
    outline: none;

    &:focus, &.focus {
      outline: none;

      & + label {
        @extend .small;
        top: -16px;
      }
    }
  }

  input[type="text"], input[type="email"], input[type="password"], input[type="tel"] {
    padding: 0 10px;
    line-height: 32px;
  }

  textarea {
    padding: 10px 10px 0 10px;
    line-height: 22px;
    resize: none;
    overflow: hidden;
  }

  &.has-error {
    input[type="text"], input[type="email"], input[type="password"], input[type="tel"], textarea.form-input {
      border-color: $error-color;
    }

    label {
      color: $error-color;
    }
  }
}

.has-error .inp-err {
  color: $error-color;
}

.checkbox-icon, .checkbox-color, .checkbox-list, .checkbox, .radio {
  position: relative;

  input[type="checkbox"], input[type="radio"] {
    display: none;
    & + label {
      cursor: pointer;
    }
  }
}

.checkbox, .radio {
  padding: 10px 0;

  input[type="radio"], input[type="checkbox"] {
    & + label {
      @extend .block;
      @extend .rel;
      color: $darkest;
      transition: color $time;
      margin-left: 40px;
      line-height: 25px;

      &:before {
        @extend .block;
        @extend .abs;
        @include square($check-size);
        content: '';
        box-sizing: border-box;
        vertical-align: middle;
        background-color: white;
        border: 1px solid $light;
        top: 0;
        left: -40px;
      }
    }

    &:checked + label:before {
      border-color: $black;
    }
  }
}

.checkbox {
  input[type="checkbox"] {
    & + label:before {
      line-height: $check-size;
      font-family: 'Material icons';
      font-size: 12px;
      color: white;
      text-align: center;
      transition: background-color $time, border $time;
    }

    &:checked + label:before {
      background-color: $black;
      content: '\e876';
    }
  }
  &.disabled {
    input[type="checkbox"] {
      & + label {
        color: $grey;
        background: #fff !important;
        &:hover {
          color: $grey;

          &:before {
            border-color: $light;
          }
        }
      }
    }
  }
}

.radio {
  input[type="radio"] {
    & + label {
      &:before {
        transition: border $time;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        left: -35px;
        height: 15px;
        width: 0;
        background-color: $black;
        transition: all $time;
      }
    }

    &:checked + label:after {
      width: 15px;
    }
  }
}

.checkbox-icon, .checkbox-list {
  input[type="checkbox"], input[type="radio"] {
    & + label {
      text-align: center;
      display: block;
      box-sizing: border-box;
      line-height: calc(#{$btn-height} - 8px);
      height: $btn-height;
      padding: 4px;
      font-size: 13px;
      //vertical-align: middle;

      span {
        display: inline-block;
        //vertical-align: middle;
        line-height: 1;
      }
    }

    &:checked + label {
      background-color: $black !important;
      color: white;
      &:hover {
        background-color: #616873 !important;
        @include respond-to(m) {
          background-color: $black !important;
        }
      }

    }

    &[disabled] + label {
      color: $grey;
    }
  }
}

.checkbox-icon {
  float: left;
  margin-top: 9px;
  margin-right: 9px;

  &:last-child{
    margin-right: 0;
  }

  input[type="checkbox"], input[type="radio"] {
    & + label {
      border: 1px solid $light;
      @include square($btn-height);
      display: flex;
      justify-content: center;
      align-items: center;
      &.active {
        border: 1px solid $darkest;
      }
    }

    &[disabled] + label:before {
      content: '';
      position: absolute;
      display: block;
      bottom: -11px;
      right: 50%;
      height: 68px;
      width: 1px;
      background-color: $light;
      transform: rotate(45deg);
    }
  }
}

.checkbox-list {
  input[type="checkbox"], input[type="radio"] {
    & + label {
      height: $btn-height;
    }
  }

  &:not(:last-child) {
    input[type="checkbox"], input[type="radio"] {
      & + label {
        border-bottom: 1px solid $light;
      }
    }
  }
}

$color-default: #dee7ef, #cfd8e0, #bec5cf, #abb3bd, #969ea8, #838a94, #6c737e, #646c76;
$color-active: #ffffff, #9f9697, #000002, #ae0000, #f09d00, #149062, #1e2e93, #646c76;

.checkbox-color {
  input[type="checkbox"], input[type="radio"] {
    & + label {
      height: $btn-height;
      width: 100%;
      display: block;
      color: white;
      padding: 10px;
      box-sizing: border-box;
      transition: background-color $time;
    }

    &:checked + label {
      position: relative;

      &:after {
        content: '\E5CD';
        font-family: 'Material Icons';
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
        text-shadow: 0px 0px 1px rgba(0,0,0,.4);
      }
    }
  }

  @for $i from 1 through length($color-default) {
    &:nth-child(#{$i}) {
      label {
        background-color: nth($color-default, $i);
      }

      input[type="checkbox"], input[type="radio"] {
        &:checked + label {
          background-color: nth($color-active, $i);
        }
      }
    }
  }

  &:first-child {
    input[type="checkbox"], input[type="radio"] {
      &:checked + label {
        //border: 1px solid $lightest;
        border-bottom: none;

        span {
          color: $grey;
        }
      }
    }
  }

  &:last-child {
    input[type="checkbox"], input[type="radio"] {
      &:checked + label {
        background-image: url('%=static=%general/patterns/8.png');
      }
    }
  }
}

@media (min-width: $screen-md-min) {
  .btn {
    &:hover:before {
      transform: scale(1, 1);
      transform-origin: left center 0;
    }

    &:hover, &:active {
      outline: none;
    }
  }

  .btn-light:hover {
    color: white;
  }

  input.btn-light:hover {
    background-color: $black;
  }
  input.btn-dark:hover {
    background-color: $darkest;
  }

  .checkbox, .radio {
    input[type="radio"], input[type="checkbox"] {
      & + label {
        &:hover {
          color: $black;
          &:before {
            border-color: $black;
          }
        }
      }
    }
  }

  .checkbox-icon, .checkbox-list {
    input[type="checkbox"], input[type="radio"] {
      &:not([disabled]) + label:hover {
        background-color: $light;
        &:checked {
          background-color: $black;
        }
      }
    }
  }
}

.delivery-showroom {
  //display: none;
}

.delivery-option {
  display: none;

  &.active {
    display: block;
  }
}

.signin-popup-content form label:first-letter {
  text-transform: capitalize;
}
