.w100p { width: 100% !important; }
.w100  { width: 100% }

.m-r--11 { margin-right: -11px; }

.m-b-5 { margin-bottom: 5px; }
.m-t-5 { margin-top: 5px; }
.m-b-10 { margin-bottom: 10px; }
.m-t-10 { margin-top: 10px; }
.m-t-20 { margin-top: 20px; }
.m-b-30 { margin-bottom: 30px; }
.m-t-30 { margin-top: 30px; }
.m-t-40 { margin-top: 40px; }
.m-b-40 { margin-bottom: 40px; }
.m-b-60 { margin-bottom: 60px;}

.m-l-10 { margin-left: 10px; }
.m-r-10 { margin-right: 10px; }
.m-r-20 { margin-right: 20px; }
.m-l-20 { margin-left: 20px; }

//padding
.padding-20 { padding: 20px; }

.p-t-10 { padding-top: 10px; }
.p-b-10 { padding-bottom: 10px; }
.p-t-20 { padding-top: 20px; }
.p-b-20 { padding-bottom: 20px; }
.p-b-30 { padding-bottom: 30px; }
.p-t-40 { padding-top: 40px; }
.p-b-40 { padding-bottom: 40px; }

.p-l-10 { padding-left: 10px; }
.p-l-20 { padding-left: 20px; }
.p-r-20 { padding-right: 20px; }

.small-w {
    max-width: 300px;

    &.r, &.l { width: 300px; }
} //maybe width

//TODO: make looooop
.m-t {
    margin-top: $grid-gutter-width;
    @media (min-width: $screen-lg-min) { margin-top: $grid-gutter-big-width; }
}
.m-b {
    margin-bottom: $grid-gutter-width;
    @media (min-width: $screen-lg-min) { margin-bottom: $grid-gutter-big-width; }
}
.m-l {
    margin-left: $grid-gutter-width;
    @media (min-width: $screen-lg-min) { margin-left: $grid-gutter-big-width; }
}
.m-r {
    margin-right: $grid-gutter-width;
    @media (min-width: $screen-lg-min) { margin-right: $grid-gutter-big-width; }
}
.p-t {
    padding-top: $grid-gutter-width;
    @media (min-width: $screen-lg-min) { padding-top: $grid-gutter-big-width; }
}
.p-b {
    padding-bottom: $grid-gutter-width;
    @media (min-width: $screen-lg-min) { padding-bottom: $grid-gutter-big-width; }
}
.p-l {
    padding-left: $grid-gutter-width;
    @media (min-width: $screen-lg-min) { padding-left: $grid-gutter-big-width; }
}
.p-r {
    padding-right: $grid-gutter-width;
    @media (min-width: $screen-lg-min) { padding-right: $grid-gutter-big-width; }
}

.overflow-hidden{
    overflow: hidden;
}