.home-header-slider-cover {
  @extend .rel;
  .slick-dots {
    @include respond-to(m) {
      margin-top: -30px;
    }
  }
}

@media (min-width: $screen-md-min) {
  .anim-scroll-block.top {
    display: block;
    background-color: $lightest;
    position: absolute;
    z-index: 0;
    top: 15.65%;
    right: 40%;
    bottom: 6%;
    left: -200px;
  }
}

.home-header {
  margin: 80px 0 12%;

  &-bg {
    @include static-bg(cover);
    @extend .rel;
    height: 84vh;

    @include respond-to(t) {
      height: 70vw;
    }
  }

  @media (min-width: $screen-md-min) {
    float: right;
    width: 74.58%;

    &-bg {
      height: 75vh;
    }
  }

  @media (max-width: $screen-sm-max) {
    margin: 0 -5%;
  }

  @media (max-width: $screen-xs-max) {
    margin: 0 calc(3px - #{$grid-gutter-width});
  }

  @media (max-width: 360px) {
    &-bg {
      height: 70vw;

    }
  }

}

.home-title {
  @extend .no-wrap;
  @extend .uppercase;
  @extend .bold;
  pointer-events: none;
  a {
    pointer-events: auto;
  }
  @media (min-width: $screen-md-min) {
    position: absolute;
    top: 40%;

    h1 {
      background-color: $lightest;
      display: inline;
      font-size: 48px;
      padding-right: 20px;
      letter-spacing: 0.05em;
      br {
        margin-left: 20px;
      }
    }

    a {
      margin-top: 30px;

      &::before {
        position: relative;
        width: 30px;
        height: 2px;
        background-color: $black;
        bottom: 0.3em;
        margin-right: 12px;
        display: inline-block;
        content: '';
        transition: width .4s, padding-right .4s .4s, margin-left .4s;
      }
      @include hover {
        &::before {
          transition: width .4s .4s, padding-right .4s, margin-left .4s .4s;
          width: 10px;
          padding-right: 20px;
          margin-left: 20px;
        }
      }
    }
  }

  @media (max-width: $screen-sm-max) {
    text-align: center;
    background-color: $lightest;
    padding: 60px 0;
    margin: 0 -5%;

    h1 {
      margin: 0;
      font-size: $h1-size;
    }

    a {
      font-size: $text-small;
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        margin-top: 2px;
        background: #000;
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    margin-left: calc(3px - #{$grid-gutter-width});
    margin-right: calc(3px - #{$grid-gutter-width});

    h1 {
      font-size: $h2-size;
    }
  }
}

.home-lookbooks {
  @extend .rel;

  &-bg {
    @include static-bg(cover);
    line-height: 1;
    display: block;
    color: white;
    @include respond-to(m) {
      background-size: cover !important;
    }
    &:hover:after {
      content: none;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  }

  &-bottom {
    @extend .bold;
    padding: 0 30px 20px 30px;
    position: relative;
    z-index: 2;
  }

  &-look {
    @extend .h4;
  }

  &-date {
    @include vertical-text;

    z-index: 2;
  }

  @media (min-width: $screen-md-min) {
    &-bottom {
      padding: 0 60px 40px 60px;
    }

    &-look {
      &:before {
        content: '';
        display: inline-block;
        position: relative;
        height: 2px;
        width: 30px;
        margin-right: 15px;
        margin-bottom: 3px;
        transition: width .4s, padding-right .4s .4s, margin-left .4s;
      }
      @include hover {
        &::before {
          transition: width .4s .4s, padding-right .4s, margin-left .4s .4s;
          width: 10px;
          padding-right: 20px;
          margin-left: 20px;
        }
      }
    }
  }

  @media (max-width: $screen-xs-min) {
    .home-lookbooks-date {
      display: none;
    }
  }
}

.home-lookbook-1 {
  .home-lookbooks-title {
    @extend .bold;
    @extend .text-center;
    font-size: 130px; //12vh;
    padding: calc(27% - 65px) 0 calc(27% - 123px);
  }

  .home-lookbooks-date {
    color: white;
    top: 240px;
    right: -155px;
  }

  .home-lookbooks-look {
    //@extend .r;
    line-height: 2;

    position: absolute;

    left: 100%;

    margin-left: -240px;
    @include respond-to(m) {
      margin-left: -140px;
    }
    white-space: nowrap;

    &:before {
      background-color: white;
      //transition: width .4s, padding-left .4s .4s, margin-right .4s;
    }
    //@include hover {
    //  &:before {
    //    transition: width .4s .4s, padding-left .4s, margin-right .4s .4s;
    //    width: 10px;
    //    padding-left: 20px;
    //    padding-right: 0;
    //    margin-right: 35px;
    //    margin-left: 0px;
    //  }
    //}
  }

  @media (min-width: $screen-md-min) {
    margin: 0px 0 4%;

    .anim-scroll-block {
      content: '';
      display: block;
      background-color: $lightest;
      position: absolute;
      z-index: 0;
      top: -60px;
      right: -200px;
      bottom: -33%;
      left: 40%;
    }
  }

  @media (max-width: $screen-sm-max) {
    margin: 30px -5%;

    .home-lookbooks-title {
      font-size: 12vw;
      padding: calc(40% - 6vw) 0 calc(40% - 6vw - 58px);
    }
  }

  @media (max-width: $screen-xs-max) {
    margin-left: calc(3px - #{$grid-gutter-width});
    margin-right: calc(3px - #{$grid-gutter-width});
  }
}

.home-lookbook-2, .home-lookbook-3 {
  .home-lookbooks-bottom {
    padding-top: calc(80.1% - 70px);
  }

  .home-lookbooks-look {
    @extend .no-wrap;
    color: $black;
    position: relative;
    top: 85px;
  }

  @media (min-width: $screen-md-min) {
    .home-lookbooks-look:before {
      background-color: $black;
    }
  }

  @media (max-width: $screen-sm-max) {
    max-width: 340px;
    margin: 0 auto 80px;

    .home-lookbooks-look {
      top: 70px;
      width: 100%;
      text-align: center;
    }

    .home-lookbooks-bottom {
      text-align: center;
    }
  }
}

@media (min-width: $screen-md-min) {
  .home-lookbook-2 {
    float: left;
    width: 56.7%;
    //margin: -18% 0 80px 0;
  }

  .home-lookbook-3 {
    float: right;
    margin: 18% 6.25% 80px 0;
    width: 31.25%;
  }
}

.home-title {
  transform: translateX(-100%);
  transition: all 1s;
}

.home-header {
  transform: translateY(-20%);
  transition: all 1s .5s;
  .pin-example {
    .img_center {
      top: 0;
      left: 0;
      transform: none;
    }
  }
}

.slick-active {
  .home-title, .home-header {
    transform: translate(0);
    transition: all 1s;
  }
}

.home-header-slider-cover {
  .home-header-video {
    width: 100%;
    position: relative;
    overflow: hidden;
    //background: #000;
    img {
      visibility: hidden;
      opacity: 0;
      transition: all .4s;
    }
    &.image-show {
      img {
        visibility: visible;
        opacity: 1;
      }
      iframe {
        visibility: hidden;
        opacity: 0;
      }
    }
    //@include respond-to(m) {
    //  //width: 100vw;
    //  //height: 55vh !important;
    //  background: #fff;
    //  iframe {
    //    display: none;
    //  }
    //  img {
    //    display: block;
    //    visibility: visible !important;
    //    opacity: 1 !important;
    //  }
    //}
  }
  iframe {
    width: 100%;
    height: 1000px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: all .4s;
    pointer-events: none;
  }
}

.home-page {
  .catalog-wish {
    right: 10px;
  }
}

.home-page {
  .btn {
    width: 180px;
  }
}

.home-lookbook-top100 {
  margin-top: 100px;
  @include respond-to(m) {
    margin-top: 80px;
  }
}