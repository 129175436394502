.v-dropdown {
  position: relative;
  @include respond-to(m) {
    overflow: hidden;
    &.open {
      overflow: visible;
    }
  }
  &.open .v-dropdown-block {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    overflow: visible;
  }
}

.v-dropdown-opener {
  @extend .pointer;
  display: inline-block;
  margin-bottom: 15px;
  user-select: none;
}

.v-dropdown-block {
  @extend .abs;
  @extend .z-v-dropdown;
  transition: all .4s;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  bottom: calc(100% + 15px);
  left: 50%;
  transform: translate(-50%, 0);
  width: 300px;
  padding: 20px;
  border: 1px solid $light;
  background-color: white;

  a[href^="tel"] {
    font-size: 15px;
  }

  &.-padding {
    width: 360px;
  }

  @media (max-width: 400px) {
    box-sizing: border-box;

    a[href^="tel"] {
      font-size: 14px;
    }
  }

  &:after, &:before {
    @extend .abs;
    display: block;
    content: '';
    height: 0;
    width: 0;
    top: 100%;
  }

  &:after {
    border: 10px solid transparent;
    border-top-color: white;
    left: calc(50% - 5px);
  }

  &:before {
    border: 11px solid transparent;
    border-top-color: $light;
    left: calc(50% - 6px);
  }

  &.bottom {
    bottom: auto;
    top: calc(100% - 9px);
    transform: translate(-46%, 0);

    &:after, &:before {
      bottom: 100%;
      top: auto;
      border-top-color: transparent;
    }

    &:after {
      border-bottom-color: white;
    }

    &:before {
      border-bottom-color: $light;
    }
  }
}

.need-help-btn .v-dropdown-block {
  @media (max-width: 767px) {
    display: none;
  }
}