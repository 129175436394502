$footer-width: 580px;

.footer {
  padding-bottom: 20px;
  @include respond-to(m) {
    padding-bottom: 80px;
  }
  .second_a {
    text-transform: uppercase;
  }
}

.instagram-list {
  @extend .cover;
  margin:0;
  width: 100%;
  .slick-prev {
    transform: translateX(10vw);
    @include respond-to(t) {
      display: none !important;
    }
  }
  .slick-next {
    transform: translateX(-10vw);
    @include respond-to(t) {
      display: none !important;
    }
  }
  &-block {
    @include static-bg(cover);
    @extend .l;
    margin: 0 $grid-gutter-width/2;
    width: calc(19.8vw - #{$grid-gutter-width});
    padding-bottom: calc(19.8vw - #{$grid-gutter-width});
  }

  @media (min-width: $screen-md-min) {
    margin: 0;
  }

  @media (max-width: $screen-xs-min) {
    .instagram-list-block {
      width: calc(27vw - #{$grid-gutter-big-width} / 4);
      padding-bottom: calc(27vw - #{$grid-gutter-big-width} / 4);
      margin: 0 $grid-gutter-width/2;
    }
  }

  @media (min-width: $screen-lg-min) {
    margin: 0;

    .instagram-list-block {
      width: calc(19.8vw - #{$grid-gutter-big-width});
      padding-bottom: calc(19.8vw - #{$grid-gutter-big-width});
      margin: 0 $grid-gutter-width/2;
    }
  }
}

.footer-menu {
  margin-top: 60px;
  padding-bottom: 10px;
  max-width: $footer-width;

  ul {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: center;
    @include respond-to(m) {
      justify-content: center;
    }
    & > li:last-child {

    }

    & > li > a, & > li > .need-help-btn {
      //@extend .h4;
      display: inline-block;
      margin: 0 20px;
      //margin-bottom: 15px;
      @media (max-width: 450px) {
        margin: 0 10px;
      }
      @include respond-to(m) {
        //display: block;
      }
    }
  }
}

.subscribe-block {
  @extend .rel;
  max-width: $footer-width;
}

.subscribe-form {
  width: auto;

  input[type="text"] {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 13px;
    border-bottom: 2px solid $black;
    font-size: 13px;

    &::-webkit-input-placeholder {
      color: $grey;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: $grey;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: $grey;
      opacity: 1;
    }

    &:focus::-webkit-input-placeholder {
      color: transparent;
    }

    &:focus::-moz-placeholder {
      color: transparent;
    }

    &:focus:-ms-input-placeholder {
      color: transparent;
    }
  }

  label {
    @extend .small;
    //top: -16px;
    top: 3px;
    left: 0;
  }

  .btn {
    @extend .abs;
    @extend .h4;
    right: 0;
    bottom: 0;
    width: 60px;
  }
}

//.need-help-btn {
//  position: relative;
//  &:before {
//    content: '';
//    position: absolute;
//    left: -20%;
//    width: 153%;
//    height: 124%;
//    bottom: 34%;
//
//    @media screen and (max-width: $screen-xs-max) {
//      left: -40%;
//      width: 200%;
//      height: 400%;
//      bottom: 34%;
//    }
//  }
//}

.instagram-block {
  margin: 120px 0 180px;

  @media screen and (max-width: $screen-xs-max) {
    margin-top: 0;
    margin-bottom: 60px;
  }

  .h1 {
    font-size: 48px;
  }

  img {
    @extend .pointer;
    width: 100%;
  }
}

.subscribe-block_title {
  font-size: $h3-size;
  margin-bottom: 20px;
  margin-top: 15px;
}

.logo-facility {
  @extend .inline-block;
  @extend .v-middle;
  @include bg-svg($by_facility);
  transition: opacity $time;

  &:hover {
    opacity: 0.5;
    &:after {
      content: none;
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .subscribe-block {
    label {
      left: 30px;
      top: 50%;
      transform: translateY(-50%);
    }

    input[type="text"] {
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 0;
      height: 70px;
      line-height: 20px;

      &:focus + label,
      &.focus + label {
        top: 10px;
      }
    }

    .btn {
      width: 160px;
      height: 70px;
    }
  }
}