.cart-total {
    border-top: 2px solid $black;
    padding: 16px 0;
}

.count-dec, .count-inc, .count-val {
    @extend .inline-block;
    @extend .text-center;
    @include square($check-size);
    line-height: $check-size;
    font-size: $text-base;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;

    &, &:focus, &:hover { outline: none; }
}

.count-dec, .count-inc {
    color: $light;

    &.active {
        color: $dark;

        &:hover { color: $black; }
    }
}

.cart-price { line-height: $check-size; }

.cart-header {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    z-index: 10;
    padding: 16px 0;
    background-color: $light-bg;

    @media (min-width: $screen-md-min) {
        margin-bottom: 10vh;
    }
}

.checkout-popup { border-bottom: 1px dashed $dark; }

.cart-dropdown { width: 280px; }

.checkout-fast {
    @media (max-width: $screen-sm-max) {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-content: center;
        height: calc(100vh - 60px);
    }
}
.cart-item{

    .inp-err{
        @extend .help-block;
        min-width: 230px;
        height: 53px;
        padding: 0 20px;
        left: -35px;
        top: -5px;

        &.active{
            @extend .help-block-error;
        }

        &:after{
            bottom: -14px;
            transform: translate(-50%, 0) rotate(-270deg);
            top: initial;
            right: calc(50% - 14px);

            @media screen and (min-width: $screen-lg){
            right: calc(50% - 18px);
            }
        }

        @media screen and (max-width: $screen-sm-min){
            background-color: #000;
            position: absolute;
            color: #fff;

            &:after{
                display: block;
            }
        }
    }
}
.item-disabled {

    a, span, .cart-price{
        color: #b2b8bf;
    }
}

.unavailable-status {
    background-color: #b5bec3;
    width: 100px;
    height: 24px;
    color: #fff;
    text-align: center;
    line-height: 24px;
}


