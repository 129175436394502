//pin image
.pin-example {
  @extend .rel;

  * {
    box-sizing: border-box;
  }

  .img_center {
    // @extend .block;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    //@include respond-to(m) {
    //  width: auto;
    //  height: 100%;
    //}
    img {
      width: 100%;
      height: auto;
      //@include respond-to(m) {
      //  width: auto;
      //  height: 100%;
      //}
    }

  }
}

@keyframes before-dot {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  20% {
    opacity: 1;
  }
  80% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
}

.image-dot {
  cursor: pointer;
  height: 16px;
  width: 16px;
  border: 2px #fff solid;
  @extend .abs;
  border-radius: 100%;
  &:after, &:before {
    @extend .abs;
    border-radius: 100%;
    border: 1px #fff solid;
  }
  &:after, &:before {
    content: '';
    left: 0;
    top: 0;
    opacity: 0.5;
    width: 200%;
    height: 200%;
    margin-left: -50%;
    margin-top: -50%;
    box-sizing: border-box;
  }

  &:not(:hover), &.active:hover {
    transition: none;
    &:after, &:before {
      transition: none;
    }
    &:after {
      animation: before-dot 3s 2s infinite;
    }
    &:before {
      animation: before-dot 3s infinite;
    }
  }

  //&.animation-open {
  //  .image-dot_text {
  //    transition: width 0.6s 0.3s, opacity 0.5s 0.4s, visibility 0.1s;
  //  }
  //  .dot-input_title {
  //    transition: opacity 0.1s 0.9s;
  //  }
  //  .dot-input_src {
  //    transition: opacity 0.1s 0.9s, border 0.2s;
  //  }
  //}
  //
  //&.animation-close {
  //  .image-dot_text {
  //    transition: width 0.6s 0.3s, opacity 0.5s 0.3s, visibility 0.1s 0.9s;
  //  }
  //  .dot-input_title {
  //    transition: opacity 0.1s;
  //  }
  //  .dot-input_src {
  //    transition: opacity 0.1s, border 0.2s;
  //  }
  //}

  &.active {
    z-index: 1000 !important; //to hide under elements
    @include respond-to(m) {
      z-index: 7 !important
    }
    &, &:after, &:before {
      background-color: black;
    }
    .image-dot_text {
      visibility: visible;
      opacity: 1;
    }
    .dot-input_title, .dot-input_src {
      opacity: 1;
    }
  }

  &.right {
    .image-dot_text {
      left: auto;
      right: calc(100% + 50px);
      &:before {
        left: auto;
        right: -51px;
      }
    }
  }
}

.image-dot_text {
  transition: all .5s;
  @extend .text-center;
  @extend .abs;
  visibility: hidden;
  background-color: black;
  padding: 20px 28px;
  opacity: 0;
  width: auto;
  font-size: 10px;
  left: 50% !important;
  top: 50% !important;
  right: auto !important;
  transform: translate(-50%, -50%);
  margin: 0 !important;
  z-index: 3;
  @include respond-to(m) {
    position: fixed;
  }
}

.image-dot_active-bg {
  @extend .abs;
  left: -6px;
  top: -6px;
  bottom: -6px;
  right: -6px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 100%;
}

.dot-input_title {
  color: white;
  font-size: 18px;
  font-family: $RegularFont;
  white-space: nowrap;
  width: 100%;
  text-align: center;
}

.dot-input_text {
  color: white;
  font-size: 13px;
  font-family: $RegularFont;
  white-space: nowrap;
  width: 100%;
  text-align: center;
}

.dot-input_price {
  color: white;
  font-size: 18px;
  font-family: $BoldFont;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  margin-top: 15px;
}

.dot-input_src {
  color: white;
  border-bottom: 1px solid transparent;
  &:hover {
    color: white;
    border-bottom-color: white;
  }
}