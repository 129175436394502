.block-right {
  @extend .fix;
  @extend .z-right;
  @extend .border-left-light;
  width: 280px;
  padding: 15px 20px 20px;
  top: 0;
  bottom: 0;
  right: -340px;
  background-color: white;
  transition: right $time;
  z-index: 12;
}

.block-right_title {
  @extend .bold;
  @extend .small;
  @extend .border-bottom-light;
  padding-bottom: 15px;
}

.block-right_content {
  min-height: calc(100% - 100px);
  box-sizing: border-box;
  // overflow-y: auto;
  @include respond-to(m) {
    min-height: auto;
  }
}

.block-right_footer {
  padding-top: 20px;
}

.feedback-open #feedback-right {
  right: 0;
}

.cart-open {
  overflow: hidden !important;
  #cart-right {
    right: 0;
  }
}

//specific for diff blocks