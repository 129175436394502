body {
  font-size: $text-base;
  color: $black;
  @extend .regular;
  letter-spacing: 0.5px;

  &.scrollable-html {
    @include respond-to(m) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      & > * {
        -webkit-overflow-scrolling: auto;
      }

    }
  }
}

html {
  &.scrollable-html {
    @include respond-to(m) {
      overflow: hidden;
      width: 100%;
      height: 100%;
    }
  }
}

.padding-page .footer {
  padding-bottom: 70px;
}

.border-left {
  border-left: 1px solid $lightest;
}

.border-right {
  border-right: 1px solid $lightest;
}

.border-top {
  border-top: 1px solid $lightest;
}

.border-bottom {
  border-bottom: 1px solid $lightest;
}

.border-left-light {
  border-left: 1px solid $light;
}

.border-right-light {
  border-right: 1px solid $light;
}

.border-top-light {
  border-top: 1px solid $light;
}

.border-bottom-light {
  border-bottom: 1px solid $light;
}

.bg-lightest {
  background-color: $lightest;
}

.light-line {
  height: 1px;
  background-color: $light;
}

.square {
  //padding-bottom: 100%; in script
  //maybe it works only for product. TODO: check and move if necessary
  &.bg-lightest, &.square-text {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.bg-lightest {
    padding: 20px;
    @media (max-width: $screen-sm-max) {
      padding-bottom: 47px;
    }
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    max-width: 424px;
    margin-left: auto;
    margin-right: auto;
  }
}

.square-content {
  ul {
    padding-left: 15px;
    color: $dark;

    li:before {
      content: '';
      display: block;
      position: relative;
      height: 2px;
      width: 2px;
      top: 0.8em;
      left: -15px;
      border-radius: 1px;
      background-color: $black;
    }
  }
  * {
    font-family: $RegularFont !important;
    color: $dark !important;
  }
  h2 {
    color: #000 !important;
    font-weight: bold !important;
  }
}

.cross-table, .mobile-table {
  @extend .w100p;
  @extend .small;
  margin: 5px 0;

  td, th {
    padding: 16px 10px;
  }

  td {
    @extend .text-center;
  }

  th {
    @extend .text-gray;
    @extend .regular;
  }
}

.cross-table {
  @extend .cover;

  tr {
    transition: background-color $time;

    &:hover {
      background-color: fade_out(#ecf1f7, 0.5);
    }

    &:first-child {
      td, th {
        border-top: 1px solid $light;
      }
    }
  }

  td, th {
    @extend .rel;
    border-bottom: 1px solid $light;

    &::after {
      @extend .abs;
      content: "";
      background-color: white;
      z-index: -1;
      transition: background-color $time;
    }

    &:hover::after {
      @extend .w100p;
      height: 1000px;
      top: -500px;
      left: 0;
      background-color: fade_out(#ecf1f7, 0.5);
    }
  }

  th {
    @extend .text-left;
  }
}

.mobile-table {
  td {
    border-bottom: 1px solid $light;
  }

  th {
    border-top: 1px solid $light;
  }
}

.small-w-left {
  @media (min-width: $screen-md-min) {
    margin-right: $btn-width + $grid-gutter-width;
  }

  @media (min-width: $screen-lg-min) {
    margin-right: $btn-width + $grid-gutter-big-width;
  }
}

//components
.errorpage {
  height: calc(100vh - 126px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.errorpage-title {
  @extend .bold;
  font-size: $btn-height;
  color: $light;
}

.delivery-page {
  @media (max-width: $screen-xs-max) {
    .col-sm-7 {
      text-align: center;
    }
  }
}

.account-content {
  @extend .middle;
  max-width: 620px;
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
}

.address-form {
  //@media (max-width: $screen-xs-max) {
  //  background-color: $light-bg;
  //  padding-top: 30px;
  //  position: relative;
  //
  //  &:after {
  //    position: absolute;
  //    display: block;
  //    font-family: 'Material Icons';
  //    content: '\e22b';
  //    top: 20px;
  //    right: 20px;
  //  }
  //
  //  .form-group {
  //    input[type="text"], input[type="email"], input[type="password"], input[type="tel"] {
  //      padding-left: 0;
  //      padding-right: 0;
  //    }
  //
  //    label {
  //      left: 0;
  //    }
  //  }
  //}
}

.inp-err {
  color: $error-color;
  @include respond-to(m) {
    z-index: 9;
  }
}

.other-address {
  display: none;
}

.size-block {
  margin-right: -10px;
}

.soc-fb, .soc-ins, [class^="ico-"] {
  @extend .inline-block;
  @extend .v-middle;
}

.soc-fb, .soc-ins,.soc-yt,.soc-pin {
  margin: 0 20px;
}

.soc-fb {
  transform: scale(1.4, 1.4);
}

.ico-delivery, .ico-pack, .ico-fly, .ico-sea {
  margin-right: 10px;
}

.ico-fb, .soc-fb {
  @include bg-svg($fb_share);
}

.soc-ins {
  @include bg-svg($inst_share);
}

.ico-pin, .soc-pin {
  @include bg-svg($pin_share);
}

.ico-twitter {
  @include bg-svg($twitter_share);
}

.ico-telegram {
  @include bg-svg($telegram_share);
}

.ico-google {
  @include bg-svg($google_share);
}

.ico-viber {
  @include bg-svg($viber_share);
}

.ico-whatsapp {
  @include bg-svg($whatsapp_share);
}

.ico-messenger {
  @include bg-svg($facebook_share);
}

.ico-delivery {
  @include bg-svg($truck_delivery);
}

.ico-pack {
  @include bg-svg($bag_delivery);
}

.ico-fly {
  @include bg-svg($plane_delivery);
}

.ico-sea {
  @include bg-svg($boat_delivery);
}

.soc-yt {
  @include bg-svg($yt);
  vertical-align: middle;
}
.soc-pin {
  vertical-align: middle;
}
.soc-ins {
  vertical-align: middle;
}
.soc-fb {
  vertical-align: middle;
}
.paused {
  .image-dot:not(:hover)::after, .image-dot.active:hover::after, .image-dot:not(:hover)::before, .image-dot.active:hover::before {
    animation-play-state: paused;
  }
}

.fast-header {
  padding: 16px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #f8fafc;
  box-sizing: border-box;
}

.mask-popup {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  @include respond-to(m) {
    cursor: pointer;
  }
}

.popup-close {
  position: relative;
  z-index: 2;
}

.logout-form {
  display: inline-block;
  input {
    border: none;
    background: none;
    margin-left: 10px;
  }
}

.signin-popup {
  .mask-popup {
    display: none;
  }
}

.btn {
  cursor: pointer;
}

.contact {
  @include respond-to(m) {
    margin-bottom: 50px;
  }
  &_wrap {
    margin-top: 60px;

  }
  &_item {
    display: inline-block;
    border-right: 1px solid #e1e9f2;
    margin-bottom: 40px;

    margin-right: 60px;
    padding-right: 60px;
    &:first-child, &:last-child {
      border: none;
    }
    &:first-child {
      display: block;
    }
    @include respond-to(m) {
      display: block;
      border: none;
      padding-right: 0;
      margin-right: 0;
    }
  }
  &_title {
    font-family: $BoldFont;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  &_text {

  }
  &_line {
    display: block;
    margin: 30px auto;
    height: 50px;
    width: 1px;
    background: #e1e9f2;
  }
}

#map {
  width: 100%;
  height: 420px;
}

.map_relative {
  position: relative;
}

.first-popup {
  //display: flex;
  //justify-content: space-between;
  max-width: 600px;
  @include respond-to(m) {
    width: 90%;
  }
  div {
    margin-bottom: 30px;

  }
}

.article-container {
  .blog-catalog-img {
    padding-bottom: 0;
    height: 600px !important;
    @include respond-to(m) {
      height: 60vw !important;

    }
  }
}

.cart-header-close {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.sps-popup {
  padding: 60px 100px;
  .h2 {
    margin-bottom: 20px;
  }
}

.loading {
  display: none;
  padding: 20px 0;
}

.barlittle {
  background-color: #000;
  border-left: 1px solid #111;
  border-top: 1px solid #111;
  border-right: 1px solid #333;
  border-bottom: 1px solid #333;
  width: 10px;
  height: 10px;
  float: left;
  margin-left: 5px;
  opacity: 0.1;
  -moz-transform: scale(0.7);
  -webkit-transform: scale(0.7);
  -moz-animation: move 1s infinite linear;
  -webkit-animation: move 1s infinite linear;
}

#block_1 {
  -moz-animation-delay: .4s;
  -webkit-animation-delay: .4s;
}

#block_2 {
  -moz-animation-delay: .3s;
  -webkit-animation-delay: .3s;
}

#block_3 {
  -moz-animation-delay: .2s;
  -webkit-animation-delay: .2s;
}

#block_4 {
  -moz-animation-delay: .3s;
  -webkit-animation-delay: .3s;
}

#block_5 {
  -moz-animation-delay: .4s;
  -webkit-animation-delay: .4s;
}

@-moz-keyframes move {
  0% {
    -moz-transform: scale(1.2);
    opacity: 1;
  }

  100% {
    -moz-transform: scale(0.7);
    opacity: 0.1;
  }
}

@-webkit-keyframes move {
  0% {
    -webkit-transform: scale(1.2);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(0.7);
    opacity: 0.1;
  }
}

.checkout_form {
  max-width: 660px;
  margin: 0 auto;
  position: relative;
  height: calc(100% - 20vh - 55px);
}

.checkout_step {
  opacity: 0;
  visibility: hidden;
  transform: translateX(-50%);
  transition: all .5s;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  &.open {
    opacity: 1;
    visibility: visible;
    transform: translateX(0%);
    z-index: 3;
  }

  .form-group {
    margin-left: auto;
    margin-right: auto;
  }

  .checkout_title {
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
  }

  .tab-pane-ukr {
    max-width: 341px;
    margin: 0 auto;

    @include respond-to(m) {
      max-width: 300px;
      padding-left: 20px;
    }

  }

  .rad-address-details {
    max-width: 315px;
    margin: 0 auto;
  }
}
