.regular {
  font-family: $RegularFont;
  font-weight: 400;
}

.bold {
  font-family: $BoldFont;
  font-weight: 400;
}

//icons
.material-icons {
  font-family: 'Material Icons', sans-serif;
  font-size: 18px;
  line-height: inherit;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5 {
  font-size: $text-base;
}

.h1 {
  font-size: $h1-size;
  //@extend .uppercase;
  @extend .bold;
}

.h2 {
  font-size: $h2-size;
  @extend .bold;
}

.h2-margin {
  margin: 20px 0;
}

.h3 {
  font-size: $h3-size;
  @extend .regular;
}

.h4 {
  font-size: $h4-size;
  @extend .uppercase;
  @extend .bold;
}

.h4-margin{
  margin: 12px 0 10px;
}

.small {
  font-size: $text-small;
}

.base {
  font-size: $text-base;
}

.text-gray {
  color: $grey;
}

.text-dark {
  color: $dark;
}

p {
  @extend .text-dark;
}

a {
  @extend .inline-block;
  @extend .rel;
  color: $black;
  text-decoration: none;

}

.a_style {
  position: relative;
  display: inline-block;
  line-height: initial;

  &:after {
    @extend .abs;
    @extend .block;
    content: '';
    height: 1px;
    width: 0;
    bottom: 0;
    left: 0;
    transition: width $time;
    background-color: $black;
  }

  &:hover:after {
    width: 100%;
  }

  &.text-gray, .text-gray & {
    &:after {
      background-color: $grey;
    }
  }

  &.text-dark, .text-dark & {
    &:after {
      background-color: $dark;
    }
  }
}