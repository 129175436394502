//some popular styles. simple to use in html
//also there are some functions

.l { float: left }
.r { float: right }

.text-left { text-align: left }
.text-right { text-align: right }
.text-center { text-align: center }

.middle {
    margin-left: auto;
    margin-right: auto;
}

.no-appearance {
    -moz-appearance: none;
 -webkit-appearance: none;
}

.v-top { vertical-align: top }
.v-middle { vertical-align: middle }
.v-bottom { vertical-align: bottom }

.pointer { cursor: pointer }
.cursor-default { cursor: default }

.rel { position: relative }
.abs { position: absolute }
.fix { position: fixed }

.hide { display: none }
.block { display: block }
.show { display: block!important }
.inline-block { display: inline-block }

.clear { clear: both }

.cover { overflow: hidden }

.transparent { opacity: 0; }

.underline { text-decoration: underline }
.uppercase { text-transform: uppercase }

.no-decor { text-decoration: none }
.no-wrap { white-space: nowrap }

.horisontal li { @extend .l; }

.mobile-center {
    @media (max-width: $screen-xs-max) {
        text-align: center;
    }
}

.tablet-center {
    @media (max-width: $screen-sm-max) {
        text-align: center;
    }
}