.dropdown {
    position: relative;
    font-size: 13px;
    width: $btn-width;


    &.open {
        .dropdown-head {
            border-bottom: none;

            &:after { transform: rotate(180deg); }
        }

        .dropdown-block { display: block; }
    }

    &.right .dropdown-block {
        left: auto;
        right: 0;
    }
}

.dropdown-head {
    box-sizing: border-box;
    border: 1px solid $light;
    padding: 0 20px;
    background-color: white;
    height: $btn-height;
    line-height: $btn-height;
    user-select: none;
    transition: background-color .3s linear;
    cursor: pointer;
    &.flex-container{
        line-height: initial;
    }

    &:after {
        content: '\e313';
        display: block;
        float: right;
        font-family: 'Material Icons';
        font-size: 13px;
    }
    &.onesize{
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
    }
}

.dropdown-block {
    @extend .z-dropdown;
    @extend .abs;
    box-sizing: border-box;
    display: none;
    overflow: hidden;
    left: 0; top: 100%;
    width: $btn-width;
    border: 1px solid $light;
    background-color: white;
    max-height: 350px;
    overflow-y: auto;

    .checkbox { @include text-overflow; }
}

//remove later

.dropdown-content-list {
    margin: 0;

    li:not(:last-child) { border-bottom: 1px solid $light; }
}

.dropdown-search-form {
    .btn-search {
        @extend .abs;
        right: 0;
        bottom: 0;
        border: none;
        background: none;
        line-height: 32px;
        height: 32px;
    }
}

//dropdown-table
.dropdown-table {
    @media (max-width: $screen-xs-max) {
        margin-left: -17px;
        margin-right: -17px;
    }

    .vacancy-content {
        ul {
            padding-left: 40px;
        }
        li {
            @extend .text-dark;
            margin: 0 15px;
        }
    }
}

.dropdown-table-table {
    .dropdown-table-title {
        padding-left: 15px;
        padding-right: 15px;

        @media (max-width: $screen-xs-max) {
            font-size: 14px;
        }
    }

    .dropdown-table-content {
        padding: 0 15px;
    }

    @media (max-width: $screen-xs-max) {
        .dropdown-table-title, .dropdown-table-content {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

.dropdown-table-row {
    border-top: 2px solid $black;

    &:last-child { border-bottom: 2px solid $black; }

    &.open {
        .dropdown-table-title { border-bottom: 1px solid $light; }

        .dropdown-icon {
            transform: none;

            &:after { content: '\e15b'; }
        }

        .dropdown-table-content { display: block; }
    }
}

.dropdown-icon {
    @extend .inline-block;
    @extend .v-middle;
    margin-right: 16px;
    transform: rotate(45deg);

    &:after {
        font-family: 'Material Icons';
        content: '\e5cd';
        font-size: 24px;
        line-height: 1;
    }
}

.dropdown-table-title {
    @extend .pointer;
    padding: 20px;

    @media (min-width: $screen-sm-min) {
        padding-left: 30px;
        padding-right: 30px;
    }

    line-height: 26px;
}

.dropdown-table-content {
    display: none;
    background-color: $light-bg;
    padding: 30px 20px;

    // @media (max-width: $screen-xs-max) {
    //     .col-sm-6 {
    //         border-top: 1px solid $light;
    //         padding-top: 20px;
    //     }
    // }

    @media (min-width: $screen-sm-min) {
        padding-left: 75px;
        padding-right: 30px;
    }
}

//select
.delivery-country,
.delivery-inter-settlement{
    .custom-select {
        //height: 32px;

        &.single .selectize-input {
            border: none;
            border-bottom: 1px solid $grey;
            box-shadow: none;
            background-image: none;
            padding: 0 10px;
            background-color: transparent;
            height: 32px;
            line-height: 32px;

            &:after{
                content: '\e313';
                //display: block;
                font-family: 'Material Icons';
                font-size: 13px;
                border: none;
                transform: translateY(-50%);
                height: 30px;
                margin-top: 0;
                display: none;

            }

            &.dropdown-active:after{
                transform: translateY(-50%) rotate(180deg);
                right: 2px;
                margin-top: 0;
            }

            &.input-active{
                background: transparent;
            }

            input {
                width: auto !important;
            }

            .item{
                font-size: 16px;
            }
        }

        .has-options.selectize-input:after {
            display: block;
        }
        .selectize-input {
            box-shadow: none;
        }
        .selectize-dropdown{
            border-color: $grey;
            box-shadow: none;
            border-radius: 0;

            &-content .option{
                text-transform: lowercase;

                &:first-letter{
                    text-transform: uppercase;
                }
            }
        }
        &.has-error .selectize-input{
            border-color: $error-color;
        }
    }
}

