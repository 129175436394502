// Common styles of the project
@import 'libraries/bootstrap';
@import 'separate-css/jquery.scrollable';
@import 'common/core';
@import 'common/template/distance';
@import 'common/template/typography';
@import 'common/template/form';
@import 'common/template/dropdown';
@import 'common/template/label';
@import 'common/template/v.dropdown';
@import 'common/template/popup';
@import 'common/layout/layout';
@import 'common/layout/header';
@import 'common/layout/footer';
@import 'common/style/catalog';
@import 'common/style/filter';
@import 'common/style/product';
@import 'common/style/block.right';
@import 'common/style/cart';
@import 'common/style/home';
@import 'common/style/blog';
@import 'common/style/about';

html, body {
  height: 100%;
}

// .page {

// }

// .page__wrapper {
//     min-height: 100%;
//     margin-bottom: -50px;
// }

// * html .page__wrapper {
//     height: 100%;
// }

// .page__buffer {
//     height: 50px;
// }

.new-btn {
  font-family: $BoldFont;
  color: rgba(0, 0, 0, 0);
  text-decoration: none;
  font-size: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  line-height: 15px;
  display: inline-block;
  margin: auto;
  height: 30px;
  position: relative;
  &:before {
    content: attr(data-content);
    display: block;
    color: $black;
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: none;
  }
  &:after {
    content: attr(data-content);
    display: block;
    color: $black;
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    position: absolute;
    top: 30%;
    left: 0;
    width: 100%;
    height: 50%;
    opacity: 0;
    background: none;
  }
  @include hover {
    &:before {
      top: -30%;
      opacity: 0;
    }
    &:after {
      top: 0%;
      opacity: 1;
    }
  }
}

@include respond-to(d) {
  .second_a {
    color: rgba(0, 0, 0, 0);
    text-decoration: none;
    line-height: 15px;
    display: inline-block;
    margin: auto;
    height: 30px;
    position: relative;
    text-transform: capitalize;
    &:before {
      content: attr(data-content);
      display: block;
      color: $black;
      transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background: none;
    }
    &:after {
      content: attr(data-content);
      display: block;
      color: $black;
      transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
      position: absolute;
      top: 30%;
      left: 0;
      width: 100%;
      height: 50%;
      opacity: 0;
      background: none;
    }
    @include hover {
      &:before {
        top: -30%;
        opacity: 0;
      }
      &:after {
        top: 0%;
        opacity: 1;
      }
    }
  }
}

.home-lookbooks-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 120%;
  line-height: 1;
  display: block;
  color: white;
  transition: all 2s;
  .home-lookbooks-title {
    transform: scale(.9);
    transition: all 2s;
  }
  &.active {
    background-size: 100%;
    .home-lookbooks-title {
      transform: scale(1);
    }
  }
}

.overhidden {
  overflow: hidden;
}

.dropdown-table-content {
  li  {
    list-style: disc;
  }
}

.need-help-btn {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    bottom: 100%;
    height: 20px;
    left: 0;
    width: 100%;
  }
}

.catalog-price-other {
  display: block;
  color: #b2b8bf;
  font-size: 13px;
  letter-spacing: .025em;
  font-family:$RegularFont;
}

.product-about {
  .a_style {
    vertical-align: top;
  }
}