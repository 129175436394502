@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@font-face {
    font-family: 'Futura PT Book';
    src: url('../fonts/futura_pt_book.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT Book';
    src: url('../fonts/futura_pt_bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT Bold';
    src: url('../fonts/futura_pt_bold.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

$RegularFont: 'Futura PT Book', Helvetica, Arial, sans-serif;
$BoldFont: 'Futura PT Bold', Helvetica, Arial, sans-serif;