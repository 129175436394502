.product-title {
  line-height: 1.1;
  margin: $grid-gutter-width 0 10px 0;

  @media (min-width: $screen-lg-min) {
    margin-top: $grid-gutter-big-width;
  }
}

.product-info {
  @media screen and (max-width: $screen-xs-max) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.right-product-about {
  margin-bottom: 20px;
  width: 280px;
  position: relative;
}

.product-about {

  @media (max-width: $screen-sm-max) {
    width: 100%;
    max-width: none;

    .right-product-about {
      width: 100%;
      max-width: none;
    }
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {

    .right-product-about {
      margin-bottom: 20px;
      width: 280px;
      position: relative;
      float: right;
    }
  }

  .price-sym {
    font-size: 12px;
    vertical-align: text-top;
    text-transform: uppercase;
  }

  .catalog-price_old .price-sym {
    font-size: 12px;
  }

  .checkbox-icon input[type="radio"] + label[for="check-0"] {
    //&:hover {
    //  background-color: #000;
    //}
    line-height: initial;

  }
}

.socials-block {
  font-size: 0;
}

.social-link {
  @extend .text-center;
  @include square(34px);
  line-height: 34px;
  border: 1px solid $light;
  margin-left: 6px;
  transition: all 200ms linear;
  font-size: 16px;

  i {
    vertical-align: baseline;
  }

  &:hover {
    border-color: #000;
  }
}

.product-image_cover {
  padding-top: 40px;

  .product-label {
    display: inline-block;
    clear: none;
    margin-right: 10px;
    // width: 100px;
    // height: 40px;
    // line-height: 40px;
  }

  @media (min-width: $screen-md-min) {
    .product-label {
      width: 100px;
      height: 40px;
      line-height: 40px;
    }

    .catalog-wish {
      display: block;
    }
  }

  @media (max-width: $screen-xs-max) {
    padding: 0;
    margin: 0 11px;

    .gallery-cover {
      margin: 0 -18px;
    }
  }
}

.set-image_cover {
  padding-top: 0;

  @media (max-width: $screen-xs-max) {
    .set-gallery-cover {
      margin: 0 -19px;
    }
  }
}

.set-gallery-cover, .gallery-cover {
  @extend .rel;
}

.gallery-cover {
  margin-top: $grid-gutter-width;

  @media (min-width: $screen-lg-min) {
    margin-top: $grid-gutter-big-width;
  }
}

$gallery-list-width: 60px;

.gallery-list {
  margin: 0;

  .gallery-list-item {
    @extend .pointer;
    @extend .block;
    @extend .rel;
    @include static-bg(cover);
    &, &:active, &:hover {
      outline: none;
    }
  }
}

.product-image_contain {
  @include static-bg(contain);
}

@media (max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
  .set-page {
    margin-top: 15vw;
  }

  .set-catalog-container, .set-table-link {
    position: relative;
    //top: 100vw;
    top: 88vw;
  }

  .set-image_cover {
    top: calc(-31vw - 240px);
  }
}

@media (max-width: $screen-xs-max) {
  .set-catalog-container {
    //margin-top: calc(100vw / 2.5 * 3.3 + 50px);
  }

  .set-info-mobile {
    margin-top: calc(100vw / 2.5 * 3.3 + 50px);
  }

  .set-image_cover {
    position: absolute;
    top: 180px;
    left: 8px;
    right: 8px;
  }
}

@media (min-width: $screen-sm-min) {
  .gallery-cover_scroll {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
  }

  .set-gallery-cover_scroll {
    top: 0px;
    left: 0px;
    bottom: 20px;
    z-index: 2;
  }

  .gallery-list {
    width: ($gallery-list-width + 10px);

    .gallery-list-item {
      height: 90px;
      width: $gallery-list-width;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &:after {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $black;
        opacity: 0.2;
      }

      &.active:after {
        content: none;
      }
    }
  }
}

@media (max-width: $screen-xs-max) {
  .set-gallery-image_cover {
    display: none;
  }

  .gallery-image_cover,
  .set-gallery-image_cover {
    display: none;
  }

  .gallery-list {
    margin: 0;

    .gallery-list-item {
      //min-height: calc(100vw * 3.3 / 2.5);
      height: calc(100vh - 200px);
      background-size: contain;

    }
  }
  .product-image_cover {
    //padding-top: 2%;
    //padding: 0;
  }
  .gallery-cover {
    //margin: 0 -20px;
  }
}

//.product-image_cover {
//  padding-top: 40px;
//}

// @media (max-width: $screen-sm-max) {
//     .set-gallery-list {
//         margin: 0;

//         .gallery-list-item {
//             height: 280px;
//             width: 100%;
//         }
//     }
// }

.gallery-image_cover {
  margin-left: $gallery-list-width + $grid-gutter-width;

  @media (min-width: $screen-lg-min) {
    margin-left: $gallery-list-width + $grid-gutter-big-width;
  }
}

.gallery-image_cover, .set-gallery-image_cover {
  max-height: 575px;
  height: 575px;
}

.gallery-image, .set-gallery-image {
  @extend .pointer;
  max-height: 575px;
  height: 575px;
  cursor: url('../img/general/cursor/png/to_gallery.png'), pointer;
  &.light {
    cursor: url('../img/general/cursor/png/to_gallery_light.png'), pointer;
  }
}

.gallery-image, .set-gallery-image {
  position: relative;
  overflow: hidden;
  max-width: 473px;
  margin: 0 auto;
}

.gallery-image-inside {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  @include static-bg(contain);
  background-position: top;
  opacity: 0;
  transform: scale(1.2);
  background-color: #fff;
  &.active {
    transition: all .5s;
    opacity: 1;
    transform: scale(1);
  }
}

.set-gallery-image {
  .gallery-image-inside {
    @include static-bg(contain);
    background-position: top;
  }
}

.gallery-image {
  @include static-bg(contain);
  background-position: top;
  background-color: #fff;
}

.set-gallery-image {
  @include static-bg(contain);
  background-position: top;
}

.product-material {
  @include static-bg(cover);
}

.product-material.rectangle {
  @include static-bg(contain);
}

.product-img-rectangle {
  text-align: center;
  height: 1px;

  .product-material {
    height: 100%;
  }
}

.product-material.square .set-gallery-image {
  max-width: none;
}

.mobile-product-buttons {
  @extend .fix;
  @extend .w100p;
  @extend .z-product-buttons;
  left: 0;
  bottom: 0;

  .btn {
    @extend .l;
    width: 50%;
  }

  .btn-light {
    border: none;
  }
}

.measure-comment {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid $light;
}

@media (min-width: $screen-sm-min) {
  .measure-1, .measure-2, .measure-3, .measure-4 {
    margin-top: 0px;
    padding-bottom: 25px;
    //height: 65px;
    .col-sm-3 {
      margin-top: 11px;
    }
    .col-sm-4 {
      margin-top: 3px;
      padding-right: 0;
    }
    .light-line {
      float: right;
      transition: all .5s;
      width: 100%;
    }

  }
  .measure-1 {
    margin-top: 114px;
  }
  .measure-4{
    padding-bottom: 0;
  }
  //.measure-3 {Измеряют по самой узкой части
  //  margin-top: 40px;
  //}
}

@media (max-width: $screen-xs-max) {
  .measure-1, .measure-2, .measure-3, .measure-4 {
    text-align: center;

    .col-sm-4 {
      padding-top: 30px;
      padding-bottom: 10px;
    }
  }

  .measure-comment {
    text-align: center;
  }
}

$video-radius: 45px;

.video-bg {
  @extend .pointer;
  @extend .cover;
  top: calc(50% - #{$video-radius});
  left: calc(50% - #{$video-radius});
  border-radius: $video-radius;
  background-color: $black;

  //hover like btn

  &, &:before, &:after {
    @extend .abs;
  }

  &, &:before {
    height: $video-radius*2;
    width: $video-radius*2;
  }

  &:before, &:after {
    content: ''
  }

  &:before {
    top: 0;
    left: 0;
    background-color: $dark;
    transform: scale(0, 1);
    transform-origin: right center 0;
    transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  }

  &:after {
    top: calc(50% - 5px);
    left: calc(50% - 3px);
    height: 0px;
    width: 0px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 10px solid transparent;
    border-left: 10px solid white;
  }

  &:hover:before {
    transform: scale(1, 1);
    transform-origin: left center 0;
  }
}

//set
.set-check {
  bottom: 16px;
  right: -15px;
}

.set-title {
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1.1;
}

@media (max-width: $screen-xs-max) {
  .set-wish {
    top: 10px;
    //right: 20px;
  }
}

.set-share-btns, .set-table-link {
  @media screen and (max-width: $screen-xs) {
    float: left;
    //width: 100%;
  }
}

.catalog-price-mobile {
  background-color: #000;
  color: #fff;
  left: 0;
  right: 0;
  z-index: 8;
  position: fixed;
  height: 25px;
  line-height: 25px;
  font-size: 0;
  top: 60px;
  opacity: 0;
  transition: all 0.5s;
  span {
    font-size: 16px;
  }

  .catalog-price_old {
    font-size: 17px;
    margin-right: 10px;
  }
}

//zoom
.zoom-slider {
  height: calc(100vh - 80px);
  user-select: none;
  max-width: 50vw;
}

.zoom-img-wrapper {
  position: relative;
}

.zoom-img {
  @include static-bg(contain);
  width: 100%;
  height: calc(100vh - 80px) !important;
  cursor: url('../img/general/cursor/png/open.png'), pointer;
  &.light {
    cursor: url('../img/general/cursor/png/open_light.png'), pointer;
  }
  outline: none;
  max-width: 50vw;
}

.zoom-img-wrapper .slick-slider {
  position: static !important;
  margin: 0 auto;
}

.zoom-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 120px);
}

.zoom-index, .zoom-count {
  @extend .bold;
  font-size: 12vh;
  line-height: 2;
  color: $lightest;
  transition: transform 0.5s;
  &.rotate {
    transform: scaleX(0);

  }
}

.zoom-full {
  @extend .fix;
  @extend .z-zoom;
  @include static-bg(cover);
  //transition: background-position 0.1s;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: #fff;
    opacity: 0;
    transition: all .3s .5s;
  }
  &.hideAnim {
    &:before {
      z-index: 5;
      opacity: 1;
      transition: all .3s;

    }
  }
  cursor: url('../img/general/cursor/png/close.png'), pointer;
  &.light {
    cursor: url('../img/general/cursor/png/close_light.png'), pointer;

    .zoom-full_close {
      cursor: url('../img/general/cursor/png/close_light.png'), pointer;
    }
    .zoom-full_next {
      cursor: url('../img/general/cursor/png/next_light.png'), pointer;
    }
    .zoom-full_prev {
      cursor: url('../img/general/cursor/png/prev_light.png'), pointer;

    }
  }
  display: none;
  &_close {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: url('../img/general/cursor/png/close.png'), pointer;
  }
  &_prev, &_next {
    position: absolute;
    top: 0;
    width: 15%;
    height: 100%;
    z-index: 2;
  }
  &_next {
    cursor: url('../img/general/cursor/png/next.png'), pointer;
    right: 0;
  }
  &_prev {
    cursor: url('../img/general/cursor/png/prev.png'), pointer;
    left: 0;

  }
}

.help-block {
  position: absolute;
  top: 0%;
  left: -60%;
  background-color: rgb(0, 0, 0);
  width: 150px;
  min-height: 48px;
  color: #fff;
  text-align: center;
  transition: opacity 600ms, visibility 600ms;
  opacity: 0;
  visibility: hidden;
  margin-top: 9px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  padding: 0 10px;
  &:after {
    content: "";
    position: absolute;
    right: -14px;
    top: 50%;
    transform: translateY(-50%);
    border: 7px solid transparent;
    border-left: 7px solid #000;
  }
  &-error {
    visibility: visible;
    opacity: 1;
    z-index: 2;
  }

  @media (max-width: $screen-sm-min) {
    position: fixed;
    width: 100%;
    left: 0;
    top: calc(100% - 105px);
    margin-bottom: 48px;
    background-color: $light;
    color: #000;

    &:after {
      display: none;
    }
  }
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    margin-top: 19px;
  }
}

.set-info .help-block {

  @media screen and (min-width: $screen-sm-min) {
    position: static;
    color: #000;
    background: transparent;
    font-size: 12px;
    margin: 0;
    min-height: 0;
    width: 100%;
    max-width: 280px;
  }
  &:after {
    display: none;
  }
}

//@media screen and (max-width: $screen-xs-max) {
//  position: fixed;
//  width: 100%;
//  left: 0;
//  top: calc(100% - 105px);
//  margin-bottom: 48px;
//  background-color: #e1e9f2;
//  color: #000;
//  font-size: 16px;
//  min-height: 48px;
//}

.cart-item {
  transition: opacity .5s;
  &.change {
    opacity: 0;
  }
  .row {
    @include respond-to(m) {
      margin: 0;
    }
  }
}

.confirm {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: #000;
  color: #fff;
  text-align: center;
  padding: 30px 30px;
  z-index: 99999;
  display: none;
  min-width: 280px;
  &_title {
    margin-bottom: 15px;
    font-size: 13px;
  }
  .btn {
    width: 90px;
    border: 1px #fff solid;
    cursor: pointer;
    margin: 0 10px;
    &:hover {
      background: #fff;
      color: #000;
    }
  }

  &_mob {
    top: 0;
    @include respond-to(d) {
      display: none !important;
    }
  }
  &_desc {
    @include respond-to(t) {
      display: none !important;
    }
  }
  &-del-address {
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.gallery-list-video {
  @extend .gallery-list-item;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include bg-svg($play-arrow);
    background-color: #000;
    border-radius: 100%;
    @include respond-to(m) {
      transform: translate(-50%, -50%) scale(2);
    }
  }
  @include respond-to(m) {
    background: #000;
  }
  iframe {
    display: none;
    @include respond-to(m) {
      display: block;
      width: 100%;
      height: calc(100vw * 3.3 / 2.5);
    }
  }
}

.set-gallery-cover {
  .gallery-video_cover {
    @include respond-to(m) {
      position: absolute;
    }
  }
}

.gallery-video_cover {
  @extend .gallery-image_cover;
  display: none;
  @include respond-to(m) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 60px;
    margin: 0;
    z-index: 9999;
    background: #000;
    cursor: pointer;
    &:before {
      font-family: 'Material Icons';
      content: '\E5CD';
      position: absolute;
      top: 10px;
      right: 10px;
      color: #fff;
    }
  }
  iframe {
    width: 100%;
    height: 575px;

    @include respond-to(m) {
      height: calc(100% - 100px);
    }
  }
}

.product-material_video {
  cursor: pointer;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(2);

    @include bg-svg($play-arrow);
    background-color: #000;
    border-radius: 100%;
    @include respond-to(m) {
      transform: translate(-50%, -50%) scale(2);
    }
  }
  iframe {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

.product-page {
  @include respond-to(m) {
    .r.socials-block {
      float: none;
      .h4 {
        margin-bottom: 10px;
      }
      .social-link {
        margin-left: 4px;
        vertical-align: middle;
        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transition: tr;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

.cart-btn-done {
  pointer-events: none;
  display: none;
  &.hide_btn {
    display: block;
  }
}

.cart-btn-first {
  &.hide_btn {
    display: none;
  }
}

@include respond-to(d) {
  #size-popup {
    .measure-video-h {
      cursor: pointer;
    }
    .size-popup {
      overflow: hidden;
    }
    #table, #measure {
      display: block;
      position: absolute;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.5s;
      &.active {
        opacity: 1;
        visibility: visible;
        position: relative;
        transition: opacity 0.5s;
      }
    }

    #table.active {
      width: 100%;
      justify-content: center;
      display: flex;
      align-items: center;
      overflow: auto;
      .m-t-40 {
        margin-top: 0;
      }
      .hidden-xs {
        width: 100%;
      }
    }
    #measure {
      .measure_left_video {
        &:before {
          content: '';
          width: 20px;
          height: 100%;
          background: #fff;
          top: 0;
          left: 0;
          position: absolute;
          z-index: 6;
        }
        &:after {
          content: '';
          top: 0;
          width: 20px;
          height: 100%;
          background: #fff;
          right: 0;
          position: absolute;
          z-index: 6;
        }
      }
      .measure-img, .measure-video {
        width: 100%;
        margin: 40px 0;
        background: #fff;
        visibility: hidden;
        position: absolute;
        opacity: 0;
        transition: opacity 0.5s;
        display: none;
        top: 0;
        left: 0;
        &.active {
          visibility: visible;
          opacity: 1;
          display: block;
        }
      }
      .measure-img {
        display: block;
      }
    }
  }

}

#mob-cart-size {
  .popup {
    width: 270px;
  }
  .checkbox-icon {
    margin-right: 5px;
    margin-left: 5px;
  }
  .checkbox-icon_wrap {
    margin: 0 -10px;
  }
}

.cart-remove {
  position: relative;
  z-index: 2;
  &:before {
    cursor: pointer;
    content: '';
    width: 200%;
    height: 200%;
    right: 0;
    top: 0;
    position: absolute;
  }
}

.mob-cart-done {
  width: 280px;
  overflow: hidden;
  .cart-remove, .count-dec, .count-val, .count-inc, .cart-counter {
    display: none;
  }
  .price-count, .price-sym {
    vertical-align: middle;
  }
}

#payment-popup {
  @include respond-to(m) {
    .mask-popup {
      display: none;
    }
  }
}
