.scrollable {
    position: relative;

    .view {
        position: relative;
        overflow: hidden;
        z-index: 1;
    }

    .bar {
        position: absolute;
        cursor: pointer;
        z-index: 2;
        //background-color: #DDD;

        // &:hover .button {
        //     background-color: #BBB;
        // }
    }

    .active {
        // .button {
        //     background-color: #f2f6fa;
        // }

        .slider { background-color: #f2f6fa!important; }
    }

    .vertical {
        top: 0;
        right: 0;
        width: 4px;
        height: 100%;
    }

    .horizontal {
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
    }

    .slider {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #e1e9f2;
        z-index: 3;
        width: 4px;
        height: 4px;

        &:hover { background-color: #b1b9c1; }
    }

    .track {
        position: absolute;
        z-index: 2;
    }

    .overlay {
        zoom: 1;
        filter: alpha(opacity=90);
        opacity: 0.9;
        display: none;
    }

    // .button {
    //     position: absolute;
    //     height: 20px;
    //     width: 20px;
    //     text-align: center;
    //     font-size: 12px;
    //     line-height: 20px;
    //     background-color: #CCC;
    //     z-index: 3;
    // }

    // .top {
    //     top: 0 !important;
    //     &:after { content: "\25b2"; }
    // }

    // .bottom {
    //     bottom: 0 !important;
    //     &:after { content: "\25bc"; }
    // }

    // .left {
    //     left: 0 !important;
    //     &:after { content: "\25c0"; }
    // }

    // .right {
    //     right: 0 !important;
    //     &:after { content: "\25b6"; }
    // }
}