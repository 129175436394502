@mixin mobile-popup() {
  position: static;
  height: 100vh;
  width: 100% !important;
  box-sizing: border-box;
  transform: none;
}

.popup-overlay {
  @extend .z-popup;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  -webkit-overflow-scrolling: touch;

}

.popup {
  padding: 20px;
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.popup-close {
  @extend .pointer;
}

.popup-body {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.size-popup {
  width: 810px;

  @media (max-width: $screen-sm-max) {
    @include mobile-popup;
  }
}

.nosize-popup {
  width: 320px;

  @media (max-width: $screen-xs-max) {
    @include mobile-popup;
  }

  button[type="submit"] {
    overflow-y: hidden;
  }
}

.payment-popup {
  width: 490px;

  .popup-body {
    max-height: 100%;
  }

  @media (max-width: $screen-xs-max) {
    @include mobile-popup;
  }
}

.help-popup {
  @extend .delivery-popup;
  -webkit-overflow-scrolling: touch;

  &_info {
    height: 70vh;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .info_block {
    margin-bottom: 40px;
  }
}

.delivery-popup {
  width: 920px;

  .popup-body {
    max-height: 100%;
    @include respond-to(m) {
      max-height: calc(100% - 50px);
      max-width: 100%;
    }
  }

  @media (max-width: $screen-sm-max) {
    @include mobile-popup;
    position: relative;
    top: 0;
    left: 0;

    @media (max-width: $screen-xs-max) {
      .delivery-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 20px;

        i {
          margin-bottom: 10px;
          margin-right: 0;
        }
      }
    }
  }

}

.zoom-popup,
.zoom-popup-product {
  @extend .fix;
  display: none;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  padding: 40px;
  @extend .z-zoom;
}

.set-popup {
  width: 240px;
  height: 160px;
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
  line-height: 24px;

  span {
    max-width: 170px;
    text-align: center;
  }

  a {
    width: 90px;
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
    text-transform: uppercase;
  }
}