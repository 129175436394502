// File for scss-vars.
// For example: main colors, paddings and so on.

//colors

$black: #000000;

$darkest: #616873;
$dark: #6b6e73;
$grey: #b1b9c1;
$light: #e1e9f2;
$lightest: #f2f6fa;
$light-bg: #f8fafc;
$dark-red: #9e0b0f;
$red: #be4040;

$error-color: $red;

//sizes
$btn-height: 48px;
$check-size: 25px;
$btn-width: 280px;

//transition
$time: 0.2s;

//text size
$text-base: 16px;
$text-small: 13px;
$h4-size: 12px;
$h3-size: 18px;
$h2-size: 24px;
$h1-size: 36px;

               .z-about { z-index: 1; }
       .z-catalog-label { z-index: 2; }
     .z-product-buttons { z-index: 3; }
            .z-dropdown { z-index: 4; }
          .z-v-dropdown { z-index: 5; }
           .z-brandlist { z-index: 6; }
@mixin z-black-dropdown { z-index: 7; }
   @mixin z-header-menu { z-index: 8; }
        @mixin z-filter { z-index: 9; }
               .z-right { z-index: 10;}
                .z-zoom { z-index: 11;}
               .z-popup { z-index: 12;}
              .z-signin { z-index: 13;}



     .z-slick-arrows { z-index: 1; } //for zoom popup
             //.z-zoom { z-index: 2; } //for zoom popup
