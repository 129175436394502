//about
.about-img-cover {
  @extend .rel;

  @media (min-width: $screen-md-min) {
    &:before {
      content: '';
      display: block;
      background-color: $lightest;
      position: absolute;
      z-index: 0;
      top: 17%;
      right: 16%;
      bottom: -17%;
      left: -$container-gutter-width;
    }
  }
}

.about-img {
  @extend .text-center;
  @extend .rel;
  @extend .z-about;
  @include static-bg(cover);
  background-image: url('%=static=%content/about.jpg');
  color: white;
  padding: 17% 0;

  h1 {
    font-size: 130px;
    line-height: 1;
    margin: 0;
    //100 768  13.02 vw
    //55  320  17.2 vw
  }

  @media (min-width: $screen-md-min) {
    margin: 20px 5% 0;
  }

  @media (min-width: $screen-lg-min) {
    margin-left: $container-gutter-width;
    margin-right: $container-gutter-width;
  }

  @media (max-width: $screen-sm-max) {
    padding: calc(50% - 6.5vw) 0;

    h1 {
      font-size: 13vw;
    }
  }

  @media (max-width: $screen-xs-min) {
    padding: calc(50% - 8.5vw) 0;

    h1 {
      font-size: 17vw;
    }
  }
}

.about-text {
  max-width: 410px;

  @media (max-width: $screen-sm-max) {
    &:before {
      display: block;
      margin: 22px auto;
      content: '';
      height: 50px;
      width: 1px;
      background-color: $light;
    }
  }
}

.about-img-special {
  .about-img-img {
    padding-bottom: 124%;
    @include static-bg(cover);
    position: relative;
    z-index: 1;

    @media (min-width: $screen-sm-min) {
      padding-bottom: 100%;
      width: 80%;
    }
  }

  @media (max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
    .about-img-img, .about-img-sign {
      margin-left: 10%;
    }
  }

  @media (min-width: $screen-md-min) {
    &:before {
      content: '';
      display: block;
      background-color: $lightest;
      position: absolute;
      z-index: 0;
      top: 20%;
      right: 5%;
      bottom: -20%;
      left: -$container-gutter-width;
    }
  }
}

.about-img-text {
  @include vertical-text;
  right: calc(21.3% - 222px);

  @media (max-width: $screen-sm-max) and (min-width: $screen-xs-min) {
    display: block;
    right: calc(50% - 386px);
  }
}

.about-short-desc {
  @media (min-width: $screen-sm-min) {
    display: inline-block;

    &:not(:last-child) {
      border-right: 1px solid $light;
      padding-right: 20px;
    }

    &:not(:first-child) {
      padding-left: 20px;
    }
  }

  @media (max-width: $screen-xs-max) {
    &:not(:last-child):after {
      content: '';
      display: block;
      width: 40px;
      height: 1px;
      margin: 20px auto;
      background-color: $light;
    }
  }
}

// @mixin animate($args) {
//  -webkit-animation: $args;
//     -moz-animation: $args;
//      -ms-animation: $args;
//       -o-animation: $args;
//          animation: $args;
// }

// @mixin transite($args) {
//  -webkit-transition: $args;
//     -moz-transition: $args;
//      -ms-transition: $args;
//       -o-transition: $args;
//          transition: $args;
// }

// @mixin keyframes($name, $arguments) {
//     @-moz-keyframes $name { #{$arguments()}; }
//  @-webkit-keyframes $name { #{$arguments()}; }
//          @keyframes $name { #{$arguments()}; }
// }

@keyframes outerpoint {
  to {
    opacity: 0.3;
  }
  from {
    opacity: 0;
  }
}

.animap {
  @include static-bg(contain);
  // @extend .rel;
  width: 1024px;
  height: 686px;
  background-image: url('%=static=%general/map.jpg');
  margin: 140px auto 20px;

  circle {
    fill: #ffffff;
    z-index: 1;
    cursor: pointer;
    // &:hover + text, &.hover + text {
    //     transition: fill 200ms;
    //     fill: white;
    // }
  }
  //<circle cx="567" cy="317" r="3" fill="#719caf"/>
  .blue {
    fill: #719caf;
  }

  path {
    stroke: #719caf;
    stroke-dasharray: 4, 4;
    stroke-width: 2;
    fill: none;
  }
  .white {
    stroke: #719caf;
  }

  .dark {
    stroke: #fff;
    stroke-width: 3;
  }

  .animate {
    transition: stroke-dashoffset 1.6s;
  }

  .point-layout {
    opacity: 0;
    cursor: default;
    &.active {
      animation: outerpoint 600ms linear alternate infinite;
    }
  }
}

.animap-svg {
  // @extend .abs;
  @extend .block;
  // top: 0; left: 0; right: 0; bottom: 0;
}

// .animap-center, [class^="animap-line-"] {
//     @extend .abs;
// }

// [class^="animap-line-"] {
//     border: 2px solid transparent;
//     border-top: 2px dotted #719caf;
//     border-radius: 100% 100% 0 0;
// }

// .animap-center {
//     height: 6px;
//     width: 6px;
//     top: 46%;
//     left: 55.2%;
//     border-radius: 3px;
//     background-color: #719caf;
// }

// @keyframes lineone {
//     from {
//         //transform: rotate(0);
//         //height: 0;
//         width: 0;
//         left: 55.2%;
//         top: 46%;
//     }
//     to {
//         //height: 95px;
//         width: 190px;
//         left: 47.2%;
//         top: 37.8%;
//     }
// }

// .animap-line-1 {
//     height: 0;
//         width: 0;
//         left: 55.2%;
//         top: 46%;
//         transform: rotate(67deg);
//     //top: 37.8%; left: 55.2%;
//     //width: 2px; height: 2px;
//     animation: lineone 2s infinite;
//     // height: 95px;
//     // width: 190px;
//     // transform: rotate(67deg);
// }

// .animap-line-2 {
//     top: 38.2%; left: 53%;
//     height: 290px;
//     width: 580px;
//     transform: rotate(-9deg);
// }

// .animap-line-3 {
//     top: 44%; left: 53.8%;
//     height: 140px;
//     width: 280px;
//     transform: rotate(1deg);
// }

// .animap-line-4 {
//     top: 54.3%; left: 42.5%;
//     height: 420px;
//     width: 840px;
//     transform: rotate(40deg);
// }

// .animap-line-5 {
//     top: 49.5%; left: 50.1%;
//     height: 120px;
//     width: 240px;
//     transform: rotate(58deg);
// }

// .animap-line-6 {
//     top: 40.1%; left: 14.7%;
//     height: 420px;
//     width: 850px;
//     transform: rotate(-2deg);
// }

// .animap-line-7 {
//     top: 45%; left: 49.4%;
//     height: 60px;
//     width: 120px;
//     transform: rotate(1deg);
// }

// .animap-line-8 {
//     top: 52.1%; left: 31.8%;
//     height: 310px;
//     width: 620px;
//     transform: rotate(-40deg);
// }

// .animap-line-9 {
//     top: 48.2%; left: 52%;
//     height: 60px;
//     width: 130px;
//     transform: rotate(-75deg);
// }

.animap {
  position: relative;
}

$to: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$from: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$s: 2s linear;
.airplane {
  @include bg-svg($air);
  position: absolute;
  left: 55%;
  top: 46%;
  margin-top: -7px;
  margin-left: -7px;
  transition: all 0s;
  opacity: 0;
  &-1 {
    transform: rotate(-240deg) translate(0, 0);
    margin-top: 0;
    margin-left: 0;
    transition: all 0s;
    &.active {
      transform: rotate(-270deg) translate(-50px, 300px);
      opacity: 1;
      transition: opacity 0.3s linear, transform $s;
      &.active2 {
        opacity: 0;
      }
    }

  }


  &-2 {
    transform: rotate(-75deg) translate(0, 0);
    margin-top: 0;
    margin-left: 0;
    transition: all 0s;

    &.active {
      transform: rotate(-45deg) translate(50px, 400px);
      opacity: 1;
      transition: opacity 0.3s linear, transform $s;
      &.active2 {
        opacity: 0;
      }
    }
  }

  &-3 {
    transform: rotate(-120deg) translate(0, 0);
    margin-top: 0;
    margin-left: 0;
    transition: all 0s;

    &.active {
      transform: rotate(-90deg) translate(50px, 200px);
      opacity: 1;
      transition: opacity 0.3s linear, transform $s;
      &.active2 {
        opacity: 0;
      }
    }
  }

  &-4 {
    transform: rotate(-280deg) translate(0, 0);
    margin-top: 0;
    margin-left: 0;
    transition: all 0s;
    &.active {
      transform: rotate(-310deg) translate(-50px, 340px);
      opacity: 1;
      transition: opacity 0.3s linear, transform $s;
      &.active2 {
        opacity: 0;
      }
    }
  }

  &-5 {
    transform: rotate(30deg) translate(0, 0);
    margin-top: 0;
    margin-left: 0;
    transition: all 0s;
    &.active {
      transform: rotate(0deg) translate(-50px, 200px);
      opacity: 1;
      transition: opacity 0.3s linear, transform $s;
      &.active2 {
        opacity: 0;
      }
    }
  }

  &-6 {
    transform: rotate(30deg) translate(0, 0);
    margin-top: 0;
    margin-left: 0;
    transition: all 0s;
    &.active {
      transform: rotate(60deg) translate(50px, 200px);
      opacity: 1;
      transition: opacity 0.3s linear, transform $s;
      &.active2 {
        opacity: 0;
      }
    }
  }

  &-7 {
    transform: rotate(60deg) translate(0, 0);
    margin-top: 0;
    margin-left: 0;
    transition: all 0s;
    &.active {
      transform: rotate(90deg) translate(50px, 200px);
      opacity: 1;
      transition: opacity 0.3s linear, transform $s;
      &.active2 {
        opacity: 0;
      }
    }
  }

  &-8 {
    transform: rotate(90deg) translate(0, 0);
    margin-top: 0;
    margin-left: 0;
    transition: all 0s;
    &.active {
      transform: rotate(120deg) translate(50px, 200px);
      opacity: 1;
      transition: opacity 0.3s linear, transform $s;
      &.active2 {
        opacity: 0;
      }
    }
  }

  &-9 {
    transform: rotate(120deg) translate(0, 0);
    margin-top: 0;
    margin-left: 0;
    transition: all 0s;
    &.active {
      transform: rotate(150deg) translate(50px, 200px);
      opacity: 1;
      transition: opacity 0.3s linear, transform $s;
      &.active2 {
        opacity: 0;
      }
    }
  }
  &-10 {
    transform: rotate(150deg) translate(0, 0);
    margin-top: 0;
    margin-left: 0;
    transition: all 0s;
    &.active {
      transform: rotate(180deg) translate(50px, 200px);
      opacity: 1;
      transition: opacity 0.3s linear, transform $s ;
      &.active2 {
        opacity: 0;
      }
    }
  }
}

#airplane-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}