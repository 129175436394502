.catalog-selected {
  transition: opacity 0.2s;

  > a {
    display: none;
  }
}

.catalog-one {
  @extend .l;
  @extend .block;
  margin-top: 20px;
  padding-bottom: 20px;

  &:not(.set-one) {
    margin-top: 40px;
  }

  &, & a {
    &:hover, &.hover {
      &:after {
        content: none;
      }
    }
  }

  &:hover, &.hover {
    .catalog-img_hover {
      opacity: 1;
      transform: scale(1, 1);
    }

    .catalog-title span {
      background-color: $black;
      color: white;
      padding: 0 10px;
    }

    .catalog-wish {
      display: block;
    }
  }

}

.catalog-3 .catalog-one {
  width: 100%;
}

.catalog-4, .catalog-6 {
  .catalog-one {
    width: calc(50% - #{$grid-gutter-width} / 2);
  }
}

.catalog-6 .catalog-one:nth-child(3n+1) {
  width: 100%;

  .catalog-img {
    padding-bottom: 96%;
    margin-left: 14%;
    margin-right: 14%;
  }
}

.catalog-sold {
  .catalog-price, .price-sym, .catalog-price_old {
    color: $grey;
  }
}

.catalog-category, .catalog-title {
  @include text-overflow;
}

.catalog-wish {
  @extend .pointer;
  @extend .abs;
  @extend .z-catalog-label;
  @include bg-svg($heart_outline);
  top: 0;
  right: 0;
  opacity: 0.4;

  &:hover, &.active {
    @include bg-svg($heart_filled);
  }

  @media (min-width: $screen-md-min) {
    display: none;

    &.active {
      display: block;
    }
  }

  @media (max-width: $screen-xs-max) {
    display: none;
    top: 10px;
  }
}

// show wishlist-icon on product page for mobile
.item-wish {
  @media (max-width: $screen-xs-max) {
    display: block;
    top: 0;
  }
}

.catalog-img {
  @extend .rel;
  @extend .cover;
  padding-bottom: 133%;
  margin-bottom: 20px;

  &, &_hover {
    @include static-bg(contain);
  }

  &_hover {
    @extend .abs;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transform: scale(1.05, 1.05);
    transition: opacity 300ms ease, transform 600ms ease;
  }
}

.catalog-price_old {
  line-height: 20px;
  height: 20px;
  margin-bottom: -20px;
  overflow: hidden;
  text-decoration: line-through;
  .catalog-price {
    text-decoration: line-through;
  }
}

.product-about {
  .catalog-price_old {
    text-decoration: none;
    .catalog-price {
      text-decoration: line-through;
    }
  }
}

//labels
.product-label {
  @extend .small;
  @extend .bold;
  @extend .uppercase;
  @extend .l;
  clear: left;
  display: block;
  color: white;
  padding: 0 10px;
}

.catalog-label_cover {
  @extend .abs;
  @extend .z-catalog-label;
  left: 0;
  top: 0;
  max-width: calc(100% - 25px);

  .product-label {
    margin-bottom: 10px;
  }

  @media (min-width: 560px) {
    max-width: calc(100% - 50px);
  }
}

.label-new {
  background-color: $black;
  &:after {
    content: 'new in';
  }
}

.label-sold {
  background-color: $grey;
  &:after {
    content: 'sold out';
  }
}

.label-musthave {
  background-color: #465773;
  &:after {
    content: 'must have';
  }
}

.label-preorder {
  background-color: #72767E;
  &:after {
    content: 'pre-order';
  }
}

.label-last {
  background-color: #C26900;
  &:after {
    content: 'last';
  }
}

.label-sale {
  background-color: $dark-red;
  padding: 0 20px;

  &:before {
    content: '-';
  }
  &:after {
    content: '%';
  }
}

//catalog change
.catalog-filter-title {
  @media (min-width: $screen-md-min) {
    float: left;
  }
}

.catalog-change_cover,
.brand-change_cover {
  margin-top: 28px;

  @media (min-width: $screen-md-min) {
    float: right;
  }
}

@media (max-width: $screen-sm-max) {
  .catalog-change_cover, .catalog-filter-title, .brand-change_cover {
    text-align: center;
  }
}

.brand-change_cover {
  margin-top: 13px;
}

.catalog-change {
  @extend .pointer;
  background-color: $light;
  height: 15px;
  margin-left: 10px;
  transition: background 0.2s;

  &:hover {
    background-color: $grey;
  }

  &.active {
    background-color: $black;
  }

  &.catalog-3 {
    width: 12px;
  }
  &.catalog-4 {
    width: 20px;

    & > div {
      width: 2px;
      margin-left: 9px;
      float: left;
      background-color: white;
      height: 100%;
    }
  }
}

//brandlist
.letter-list {
  @extend .z-brandlist;

  a {
    @extend .small;
    text-align: center;
    display: block;
  }

  @media (min-width: $screen-sm) {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: flex-start;
    border-bottom: 1px solid $light;

    li {
      margin: 10px 0;
    }

    a {
      @include square($check-size);
      line-height: $check-size;

      &:hover {
        background-color: $lightest;

        &:after {
          content: none;
        }
      }
    }
    &.static {
      display: none;
    }

  }

  @media (max-width: $screen-xs-max) {

    display: flex;
    flex-wrap: wrap;
    margin-right: -0.055em;
    margin-left: 1px;

    li {
      display: inline-block;
      margin: 1px 0.055em;
    }

    a {
      @include square(45px);
      line-height: 45px;
      background-color: $lightest;
      border-color: $lightest;
      transition: .2s ease linear;

      &:hover {
        color: white;
        background-color: $black;

        &:after {
          height: $btn-height
        }

        span {
          position: relative;
          z-index: 1;
        }
      }
    }

    &.static {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      overflow-x: scroll;
      white-space: nowrap;
      background-color: white;
      margin: 0;
      padding: 10px 0;
      //padding: 0;
      flex-wrap: nowrap;
    }
  }
}

.letter-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media (min-width: $screen-sm-min) {
    padding: 40px 0;

    .row {
      padding-bottom: 40px;
    }
  }

  @media (max-width: $screen-xs-max) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

.letter-inline {
  flex-basis: 25%;
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  padding-bottom: 40px;

  a {
    color: $dark;
  }

  @media (max-width: $screen-xs-max) {
    display: block;
    text-align: center;
    flex-basis: 0;
  }
}

//brand content
.brand-img {
  @include static-bg(contain);
  padding-bottom: 100%;
}

.brand-text {
  p:first-child {
    margin-top: 0;
  }

  @media (min-width: $screen-sm-min) {
    column-count: 2;
    column-gap: $grid-gutter-width;
  }

  @media (min-width: $screen-lg-min) {
    column-count: 2;
    column-gap: $grid-gutter-big-width;
  }
}

@media (max-width: 559px) {
  .catalog-xs-4 .catalog-one {
    width: calc(50% - #{$grid-gutter-width} / 2);
    margin: 0;
    margin-top: 20px;
  }

  .catalog-xs-4, .catalog-4 {
    .catalog-one:not(:nth-child(2n+1)) {
      margin-left: $grid-gutter-width;
    }
  }

  .catalog-6 .catalog-one:nth-child(3n) {
    margin-left: $grid-gutter-width;
  }
}

@media (min-width: 560px) {
  //.catalog-wish {
  //    top: 0; right: 24px;
  //    width: 26px;
  //}

  .catalog-3 .catalog-one {
    width: calc((100% - (#{$grid-gutter-width} * 2)) / 3);

    &:nth-child(3n+2) {
      margin-left: $grid-gutter-width;
      margin-right: $grid-gutter-width;
    }
  }
  .catalog-4 .catalog-one {
    width: calc(25% - (#{$grid-gutter-width} * 3 / 4));

    &:not(:nth-child(4n+1)) {
      margin-left: $grid-gutter-width;
    }
  }

  .catalog-6 {
    .catalog-one {
      width: calc(25% - #{$grid-gutter-width} / 2);

      &:nth-child(3n+1) {
        width: calc(50% - #{$grid-gutter-width});
      }
      &:nth-child(3n) {
        margin-left: $grid-gutter-width;
      }
    }

    &.reverse {
      .catalog-one {
        right: 50%;

        &:nth-child(3n+1) {
          left: 50%;
          right: auto;
        }
      }
    }
  }

  .catalog-change {
    &:before, &:after {
      content: '';
      float: left;
      background-color: white;
      height: 100%;
      display: block;
    }

    &.catalog-3, &.catalog-4 {
      width: 42px;
    }
    &.catalog-3 {
      &:before, &:after {
        width: 3px;
        margin-left: 12px;
      }
    }
    &.catalog-4 {
      &:before, &:after {
        width: 2px;
        margin-left: 9px;
      }
    }
  }
}

@media (min-width: $screen-lg-min) {
  .catalog-3 .catalog-one {
    width: calc((100% - (#{$grid-gutter-big-width} * 2)) / 3);

    &:nth-child(3n+2) {
      margin-left: $grid-gutter-big-width;
      margin-right: $grid-gutter-big-width;
    }
  }
  .catalog-4 .catalog-one {
    width: calc(25% - (#{$grid-gutter-big-width} * 3 / 4));

    &:not(:nth-child(4n+1)) {
      margin-left: #{$grid-gutter-big-width};
    }
  }
}

.product-page {
  .nosize-btn {
    //display: none;
    text-align: center;
    line-height: calc(48px - 4px);
    height: 48px;
    padding: 4px;
    font-size: 13px;
  }
}

.size-error {
  width: 280px;
  text-align: center;
  font-size: 13px;
  opacity: 0;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.set-total-price {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  width: 100%;
  background-color: $black;
  color: #fff;
  height: 25px;
  line-height: 25px;
}

//back to top btn

.back-to-top {
  position: fixed;
  bottom: 55px;
  right: 0px;
  z-index: 9;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background: #000;
  cursor: pointer;
  text-decoration: none;
  transition: opacity 0.2s ease-out;
  opacity: 0;
  color: #fff;

  &:before {
    z-index: -1;
  }
}

.back-to-top.active {
  opacity: 1;
}

//.set-catalog-container .catalog-one:nth-child(3n+2){
//  margin-left: 0;
//  margin-right: 0;
//}
//
//.set-catalog-container .catalog-one:nth-child(3n){
//  margin-left: 30px;
//  margin-right: 30px;
//}

.dropdown-search-form {
  line-height: 1;
}

.flex-container {
  .catalog-price {
    white-space:nowrap;
  }
}