  $by_facility: -4px -4px 133px 13px 141px 623px './test.svg' 'by_facility';
  $close_search-mob: -4px -21px 60px 60px 141px 623px './test.svg' 'close_search-mob';
  $close_mob_menu: -4px -85px 60px 60px 141px 623px './test.svg' 'close_mob_menu';
  $bag_delivery: -4px -149px 38px 26px 141px 623px './test.svg' 'bag_delivery';
  $plane_delivery: -4px -179px 38px 22px 141px 623px './test.svg' 'plane_delivery';
  $boat_delivery: -4px -205px 38px 20px 141px 623px './test.svg' 'boat_delivery';
  $truck_delivery: -4px -229px 38px 18px 141px 623px './test.svg' 'truck_delivery';
  $play-arrow: -4px -251px 35px 35px 141px 623px './test.svg' 'play-arrow';
  $heart_outline: -4px -290px 25px 23px 141px 623px './test.svg' 'heart_outline';
  $heart_filled: -4px -317px 25px 23px 141px 623px './test.svg' 'heart_filled';
  $user-shape: -4px -344px 23px 23px 141px 623px './test.svg' 'user-shape';
  $yt: -4px -371px 20px 16px 141px 623px './test.svg' 'yt';
  $heart-small: -4px -391px 18px 16px 141px 623px './test.svg' 'heart-small';
  $inst_share: -4px -411px 17px 17px 141px 623px './test.svg' 'inst_share';
  $phone_mobile: -4px -432px 16px 16px 141px 623px './test.svg' 'phone_mobile';
  $cart_mobile: -4px -452px 16px 16px 141px 623px './test.svg' 'cart_mobile';
  $viber_share: -4px -472px 12px 16px 141px 623px './test.svg' 'viber_share';
  $google_share: -4px -492px 16px 10px 141px 623px './test.svg' 'google_share';
  $air: -4px -506px 15px 15px 141px 623px './test.svg' 'air';
  $twitter_share: -4px -525px 14px 12px 141px 623px './test.svg' 'twitter_share';
  $telegram_share: -4px -541px 14px 12px 141px 623px './test.svg' 'telegram_share';
  $facebook_share: -4px -557px 12px 13px 141px 623px './test.svg' 'facebook_share';
  $whatsapp_share: -4px -574px 12px 13px 141px 623px './test.svg' 'whatsapp_share';
  $pin_share: -4px -591px 10px 12px 141px 623px './test.svg' 'pin_share';
  $fb_share: -4px -607px 6px 12px 141px 623px './test.svg' 'fb_share';



@mixin svg-sprite-position($svg-sprite) {
  $svg-sprite-offset-x: nth($svg-sprite, 1);
  $svg-sprite-offset-y: nth($svg-sprite, 2);
  background-position: $svg-sprite-offset-x  $svg-sprite-offset-y;
}

@mixin bg-svg($svg-sprite, $repeat: no-repeat) {
    background-image: url('%=static=%svg-sprite/sprite.svg');
    @include svg-sprite-position($svg-sprite);
    width: nth($svg-sprite, 3);
    height: nth($svg-sprite, 4);
    background-repeat: $repeat;
}
