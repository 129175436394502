.label {
    @extend .inline-block;
    @extend .text-center;
    min-width: $check-size;
    background-color: $lightest;
    padding: 0 2px;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 22px;
    height:22px;
}