@charset "UTF-8";

// Default Variables

$slick-loader-path: "../img/general/" !default;
$slick-arrow-color: $black !default;
$slick-arrow-width: 50px !default;
$slick-dot-color: $black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-dot-size: 5px !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 0.6 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
  @if function-exists(image-url) {
    @return image-url($url);
  } @else {
    @return url($slick-loader-path + $url);
  }
}

/* Slider */

.slick-loading .slick-list {
  background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
}

/* Arrows */

.slick-prev, .slick-next {
  @extend .z-slick-arrows;
  position: absolute;
  display: block;
  height: 40px;
  width: $slick-arrow-width;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  bottom: 0;
  padding: 0;
  border: none;
  outline: none;

  &:hover, &:focus {
    outline: none;
    background: transparent;
    color: transparent;

    &:before, &:after {
      //opacity: $slick-opacity-on-hover;
    }
  }

  &.slick-disabled {
    &:before, &:after {
      //opacity: $slick-opacity-not-active;
    }
  }

  &:before, &:after {
    content: '';
    display: block;
    //opacity: $slick-opacity-default;
  }

  &:after {
    height: 1px;
    width: 50px;
    background-color: $black;
  }

  &:before {
    @extend .abs;
    height: 0;
    width: 0;
    top: 17px;
    border: 3px solid transparent;
  }
}

.slick-prev {
  right: 0px;
  margin-right: 50px;

  [dir="rtl"] & {
    left: auto;
    right: 0px;
  }

  &:before {
    left: -3px;
    border-right-color: $slick-arrow-color;

    [dir="rtl"] & {
      left: auto;
      right: -3px;
      border-right-color: transparent;
      border-left-color: transparent;
    }
  }
}

.slick-controls {
  position: absolute;
  bottom: 50px;
  margin: 0 auto;
  left: 25%;
  max-width: 280px;
}

.slick-prev, .slick-next {
  transition: all .4s;

  &:after {
    transition: all .4s;

  }
  @include hover {
    width: 80px;
    &:after {
      width: 70px;
    }
  }
}

.slick-prev {
  @include hover {
    //right: 30px;
  }
}

.slick-next {
  @include hover {
    //left: 30px;
  }
}

.slick-next::after {
  float: right;
}

.slick-next {
  left: 0px;
  margin-left: 50px;

  [dir="rtl"] & {
    left: 0px;
    right: auto;
  }

  &:before {
    right: -3px;
    border-left-color: $slick-arrow-color;

    [dir="rtl"] & {
      right: auto;
      left: -3px;
      border-left-color: transparent;
      border-right-color: transparent;
    }
  }
}

/* Dots */

.slick-dotted.slick-slider {
  //margin-bottom: 30px;
}

.set-slider .slick-dots {
  bottom: 60px;
}

//.set-gallery-cover

.set-gallery-cover_scroll .slick-dots {
  bottom: -20px;
}

.slick-dots {
  position: absolute;
  //bottom: 0px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0 !important;
  margin: 0 $slick-arrow-width;
  width: calc(100% - #{$slick-arrow-width} * 2);
  font-size: 0;

  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    font-style: 16px;

    &:before {
      content: none !important;
    }

    button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      &:hover, &:focus {
        outline: none;

        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }

      &:before {
        position: absolute;
        top: 7px;
        left: 7px;
        content: '';
        width: $slick-dot-size;
        height: $slick-dot-size;
        border-radius: 0;
        background-color: $slick-dot-color;
        opacity: $slick-opacity-not-active;
      }
    }

    &.slick-active button:before {
      color: $slick-dot-color-active;
      opacity: $slick-opacity-default;
    }
  }
}

.home-slider, .zoom-slider, .instagram-list {
  .slick-prev, .slick-next {
    bottom: calc(50% - 16px);
  }
  .slick-next {
    right: 0;
    left: auto;
    //
    transition: all .4s;
    @include hover {
      right: -20px;
    }
    //
  }
  .slick-prev {
    left: 0;
    //
    transition: all .4s;


  }
}

.zoom-slider {
  .slick-prev {
    right: calc(40px - 50%);
    left: auto;
    margin: 0;
  }

  .slick-next {
    right: calc(-140px - 50%);
    width: 150px;
    left: auto;
    margin: 0;

    &:after {
      width: 150px;
    }
    @include hover {
      width: 200px;
      right: calc(-190px - 50%);
      left: auto;
      &:after {
        width: 190px;
      }
    }
  }
  .slick-slide {
    transform: scale(1.2);
    transition: transform 1s, opacity 500ms ease 0s !important;
    &.slick-active {
      transform: scale(1);
      transition: transform 1s, opacity 500ms ease 0s !important;
    }
  }

}

.home-slider {
  .slick-prev {
    @include hover {
      left: -20px;
    }
  }
  @media (max-width: $screen-sm-max) {
    height: 0;
    overflow: hidden;
    padding-bottom: calc(33.33% - #{$grid-gutter-width} + 160px);
  }
  @media (max-width: $screen-xs-max) {
    padding-bottom: calc(133% - #{$grid-gutter-width} * 4 / 3 + 320px);
  }
}

.home-small-slider-cover {
  position: absolute;
  top: 80px;
  right: calc(5% - 140px);
  width: 420px;
  z-index: 2;
  background-color: black;

  .slick-slide {
    transition: all 0.2s;
    &:not(.slick-current) {
      opacity: 0.5;

    }
  }

  @media (min-width: $screen-lg-min) {
    width: 600px;
    right: 0;
  }
  @include respond-to(m) {
    display: none;
  }
}

.home-small-slide {
  @include static-bg(cover);
  //width: 140px;
  height: 92px;
  cursor: pointer;
  @media (min-width: $screen-lg-min) {
    height: 130px;
  }
}

.set-slider-wrapper {
  position: relative;
}

.set-slider {
  .slick-dots {
    //bottom: 40px;
  }

  .slick-arrow {
    //bottom: 30px;
  }

  .slick-prev {
    //left: calc(50% - 134px);
  }
  .slick-next {
    //right: calc(50% - 134px);
  }

  @media (min-width: $screen-md-min) {
    .h2 {
      margin-top: -40px;
    }

    .slick-dots {
      width: calc(50% - #{$slick-arrow-width} * 2);
    }

    .slick-next {
      //right: auto;
      //left: calc(25% + 78px);
    }

    .slick-prev {
      //left: calc(25% - 136px);
    }
  }

  //@media (max-width: 330px) {
  //  .slick-controls{
  //    width: calc(100% - 50px * 2);
  //    margin-bottom: 0 50px;
  //  }
  //  //.slick-next {
  //  //  left: auto;
  //  //  right: 20px;
  //  //}
  //  //
  //  //.slick-prev {
  //  //  left: 20px;
  //  //}
  //}
}

@media (max-width: 1024px) {
  .slick-controls {
    left: 50%;
  }
}

.article-slider {
  .slick-prev, .slick-next {
    top: 100%;
  }
  .slick-next {
    left: 100%;
    margin-left: -80px;
  }
  .h4 {
    margin-top: 15px;
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    max-width: 424px;
    margin: 0 auto;
  }

  @media (min-width: $screen-sm-min) {
    .slick-prev {
      left: auto;
      right: 80px;
    }
  }

  @media (max-width: $screen-sm-max) {
    margin-bottom: 60px;
  }

  @media (max-width: $screen-xs-max) {
    .slick-prev, .slick-next {
      //bottom: -37px;
    }

    .slick-prev {
      left: calc(50% - 60px);
      @include respond-to(m) {
        right: 100%;
        margin-right: -80px;
        left: auto;;

      }
    }

    .slick-next {
      right: calc(50% - 60px);
    }
  }
}