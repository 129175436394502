.blog-item {
  display: block;
  margin-bottom: 80px;

  .h2 {
    line-height: 1;
  }

  @media (max-width: $screen-sm-max) {
    text-align: center;
    margin-bottom: 55px;
  }
}

.link-tab:after {
  content: none;
}

.blog-img {
  img {
    display: block;
    //width: 100%;
  }
}

.blog-catalog-img {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 54%;
  display: block;
}

.blog-nav > li > a {
  margin-left: 25px;
  margin-right: 25px;
}

.article-title {
  margin-bottom: 12px;
}

.article-slider-counter {
  @extend .abs;
  @extend .text-center;

  & > div {
    font-size: 14px;
  }

  @media (min-width: $screen-md-min) {
    top: -18px;
    right: -34px;

    & > div {
      padding: 15px 4px;
    }

    .article-slider-count {
      border-top: 1px solid $light;
    }
  }

  @media (max-width: $screen-sm-max) {
    bottom: -30px;
    left: 50%;
    transform: translate(-50%, 0);

    & > div {
      display: inline-block;
      padding: 0 20px;
    }

    .article-slider-count {
      border-left: 1px solid $light;
    }
  }
}

.article-container {
  padding-bottom: 100px;
  font-size: 18px;

  li {
    @extend .text-dark;
    margin: 20px 0 20px 25px;

    &:before {
      content: '';
      position: relative;
      top: 14px;
      left: -25px;
      display: block;
      background-color: $light;
      height: 5px;
      width: 5px;
    }
  }

  .breadcrumbs li {
    margin: 0;
    color: #000;

    &:before {
      display: none;
    }
  }

  iframe {
    width: 100% !important;
    height: 410px !important;
  }
  p a {
    text-decoration: underline;
  }
  @media (min-width: $screen-md-min) {
    width: 730px;
  }
  @media (min-width: $screen-lg-min) {
    width: 730px;
  }
}

.article-breadcrumbs {
  @extend .article-container;
  padding-bottom: 0;
}

.article-quote-1, .article-quote-2 {
  @extend .h2;
  @extend .text-center;
  margin: 50px auto;
  max-width: 554px;

  &:before {
    display: block;
    margin: 22px auto;
  }
}

.article-quote-1:before {
  content: '';
  height: 50px;
  width: 1px;
  background-color: $light;
}

.article-quote-2:before {
  content: url('%=static=%general/quote.png');
}

.article-quote-author {
  @extend .regular;
  margin-top: 20px;
  font-size: $text-base;
  color: $grey;
}

.article-alt-img {
  @extend .rel;
  margin-bottom: 50px;
  text-align: center;

  img {
    width: 100%;
    //max-height: 600px;
    //max-width: 730px;
    //margin: 0 auto;
    //height: auto;

    //&.horizontal {s
    //    width: 750px;
    //    height: auto;
    //    margin: 0 auto;
    //    @include respond-to(ls-t ) {
    //        width: 100%;
    //    }
    //}

  }

  //.pin-example.horizontal {
  //    text-align: center;
  //}

}

.article-alt-img-text {
  @include vertical-text;
}

.article-ul-title {
  @extend .bold;
  padding-top: 25px;
}

.article-share-cover {
  @extend .abs;
  top: 0;
  left: -60px;

  a {
    display: block;
    margin-bottom: 10px;

  }
}