//
// Navs
// --------------------------------------------------

.nav {
  @extend .text-center;
  @include clearfix;
  margin-bottom: 0;
  padding-left: 0;

  > li {
    @extend .rel;
    @extend .inline-block;

    > a {
      @extend .h4;
      @extend .rel;
      @extend .block;
      padding: 8px 0;
      margin: 0 15px;
      transition: border-bottom .3s;
      border-bottom: 2px solid transparent;

      &:after {
        height: 2px;
      }
    }

    &.disabled > a {
      @extend .text-gray;
    }

    &.active > a {
      cursor: default;
      border-bottom: 2px solid #000;

      &:after {
        width: 100%;
      }
    }
  }

  > li > a > img {
    max-width: none;
  }
}

.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}

@media screen and (min-width: $screen-md-min) {
  .nav > li {
    &.disabled > a {
      &:hover, &:focus {
        &:after {
          background-color: $grey;
        }
      }
    }

    &.active > a {
      &:hover, &:focus {
        &:after {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: $screen-xs-max) {
  .nav-mobile-black-cover {
    @include z-black-dropdown;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    // margin-left: calc(3px - #{$grid-gutter-width});
    // margin-right: calc(3px - #{$grid-gutter-width});
    height: $btn-height;

    &.open {
      overflow: visible;
      height: 100vh;

      .nav-mobile-black {
        li.active {
          &:after {
            content: '\e316';
          }

          a {
            display: inline-block;

            &:after {
              content: '';
              display: block;
              height: 2px;
              background-color: white;
            }
          }
        }
      }
    }
  }

  .nav-mobile-black {
    position: absolute;
    height: calc(100vh - 69px);
    padding-top: $btn-height;
    background-color: $black;
    //transition: height 0.4s;
    margin: 0;
    left: 0;
    right: auto;
    top: 0;
    width: 100%;
    > li {
      background-color: $black;
      padding: 0 $grid-gutter-width;
      width: 100%;
      text-align: left;
      display: block;
      //
      &.active {
        position: absolute;
        left: $grid-gutter-width;
        top: 0;
        background: none;
        padding: 0;
        z-index: 2;
        &:after {
          display: block;
          position: absolute;
          right: $grid-gutter-width * 2;
          top: 17px;
          color: white;
          font-family: 'Material Icons';
          content: '\e313';
        }
      }

      > a {
        color: white;
        height: $btn-height;
        line-height: $btn-height;
        padding: 0;
        margin: 0;
      }
    }
  }

  .page__wrapper {
    overflow-x: hidden ;
  }
}

